<template>
  <div>

    <el-form :inline="true" class="form-inline">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-form-item label="Контрагент">
            <el-input v-model="filter.contId" placeholder="Контрагент"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="Накладная">
            <el-input v-model="filter.tripTicket" placeholder="Накладная"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="Статус">
            <el-select-v2
                v-model="filter.status"
                :options="statusList"
                placeholder="Выбрать статус"
            />
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item>
            <el-button type="primary" plain @click="filterWaybills">Поиск</el-button>
          </el-form-item>
        </el-col>

      </el-row>

    </el-form>
    <el-table :border="true" :data="waybills" style="width: 100%">
      <el-table-column prop="id" :formatter="idColumn" label="Идентификаторы"/>
      <el-table-column prop="createdAt" width="150" :formatter="formatDatesColumn" label="Создан/Начало действия"/>
      <el-table-column prop="contId" :formatter="formatContIdColumn" width="280" label="Страхователь/Накладная"/>
      <el-table-column prop="fromCityName" :formatter="formatCity" label="Направление"/>
      <el-table-column prop="cover" width="150" :formatter="formatCoverColumn" label="Покрытие"/>
      <el-table-column prop="status" :formatter="formatStatusColumn" label="Статус"/>
      <el-table-column width="100">
        <template #default="scope">
          <el-button size="small"  plain
                     @click="navigateWaybill(waybills[scope.$index])">Просмотр
          </el-button>
        </template>
      </el-table-column>
      <el-table-column width="100">
        <template #default="scope">
          <!--el-button :disabled="!waybills[scope.$index].haveFile" size="small"
                     @click="download(waybills[scope.$index])">Скачать
          </el-button-->
          <el-button :disabled="cancelDisabled(waybills[scope.$index])" size="small"
                     @click="cancel(waybills[scope.$index])">Отменить
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <br/>
    <el-pagination
        :page-size="meta.pageSize"
        :pager-count="11"
        layout="prev, pager, next"
        :total="meta.pageCount*meta.pageSize"
        @current-change="setPage"
    >
    </el-pagination>
  </div>
</template>

<script>
import store from "../store";
import moment from "moment/moment";

export default {
  name: 'WaybillList',
  data: function () {
    return {
      filter: {page: 1, id: "", contId: "", status: "", tripTicket: ""},
      statusList: [
        {value: "", label: "Все"},
        {value: "new", label: "Калькуляция"},
        {value: "registering", label: "В процессе"},
        {value: "registered", label: "Зарегистрирован"},
        {value: "failed", label: "Ошибка"},
        {value: "deleted", label: "Удален"},
      ],
      statusMap: {
        "new": "Калькуляция",
        "registering": "В процессе",
        "registered": "Зарегистрирован",
        "failed": "Ошибка",
        "deleted": "Удален",
        "cancelled": "Отменен",
        "cancelling": "Отменяется",
        "cancel_failed": "Ошибка отмены",
      }
    }
  },
  computed: {
    waybills: function () {
      return store.getters.waybills.data
    },
    meta: function () {
      return store.getters.waybills.meta
    }
  },
  created: function () {
    this.filter = store.getters.waybills.filter
    store.commit('updateWaybills', this.filter)
  },
  methods: {
    navigateWaybill(waybill) {
      this.$router.push({name: 'WaybillView', params: {id: waybill.id}})
    },
    cancelDisabled(row) {
      return row['status'] !== 'cancel_failed' && row['status'] !== 'registered'
    },
    download(row) {
      console.log(row)
    },
    cancel(row) {
      if (!confirm("Отменить полис?")) {
        return false
      }
      console.log("cancel");
      store.commit('cancelWaybill', {
        waybill: row,
        callback: (success, response) => {
          if (success) {
            row.status = "cancelling"
            setTimeout(() => {
              store.commit('updateWaybills', this.filter)
            }, 5000)
          }
          console.log(success, response)
        }
      })
    },
    formatDatesColumn(row) {
      let str = ""
      if (row['createdAt'] !== "" && row['createdAt'] !== null) {
        str += moment(row['createdAt']).utc(true).format("YYYY-MM-DD HH:mm:ss")
      }
      if (row['dateStart'] !== "" && row['dateStart'] !== null) {
        str += "\n" + moment(row['dateStart']).utc(true).format("YYYY-MM-DD")
      }
      return str
    },
    formatContIdColumn(row) {
      if (row['tripTicket'] === undefined) {
        return row['contId']
      }
      return row['contId'] + "\n" + row['tripTicket']
    },
    formatStatusColumn(row, col, val) {
      return this.statusMap[val] + "\n" + row['paymentStatus'];
    },
    idColumn(row) {
      let str = row['id']
      if (row['number'] !== undefined) {
        str += "\n" + row['number']
      }
      if (row['paymentTransactionId'] !== undefined) {
        str += "\n" + row['paymentTransactionId']
      }
      return str;
    },
    formatCoverColumn(row) {
      return row['cover'] + "\n" + row['premium']
    },
    formatCity(row) {
      return row['fromCityName'] + " -> " + row['toCityName']
    },
    formatDate(row, column, value) {
      if (value === undefined) {
        return ""
      }
      return moment(value).utc(true).format("YYYY-MM-DD")
    },
    setPage(val) {
      this.filter.page = val
      this.filterWaybills()
    },
    filterWaybills() {
      this.filter.contId = this.filter.contId.trim()
      this.filter.tripTicket = this.filter.tripTicket.trim()
      store.commit('updateWaybills', this.filter)
    }
  }
}
</script>

<style>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.info-item {
  margin-bottom: 18px;
}

.el-table .cell {
  white-space: pre;
}
</style>
