<template>
  <div>

    <el-form :inline="true" class="form-inline">
      <el-row :gutter="20">
        <el-col :span="3" :xs="4" :sm="4" :md="3">
          <el-button size="small" type="success" plain @click="createBatch">Создать пакет</el-button>
        </el-col>
        <el-col :span="4">
          <el-input size="small" v-model="filter.id" placeholder="Идентификатор"></el-input>
        </el-col>
        <el-col :span="4">
          <el-input size="small" v-model="filter.carNumber" placeholder="Номер авто"></el-input>
        </el-col>
        <el-col :span="3">
          <el-button size="small" type="primary" plain @click="filterBatches">Поиск</el-button>
        </el-col>

      </el-row>

    </el-form>
    <el-table :data="batches" style="width: 100%">
      <el-table-column>
        <template #default="scope">
          <el-button size="small" plain
                     @click="navigateBatch(batches[scope.$index])">Просмотр
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createdAtFmt" label="Дата"/>
      <el-table-column prop="status" label="Статус" />
    </el-table>
    <br/>
    <el-pagination
        :page-size="meta.pageSize"
        :pager-count="11"
        layout="prev, pager, next"
        :total="meta.pageCount*meta.pageSize"
        @current-change="setPage"
    >
    </el-pagination>
  </div>
</template>

<script>
import store from "../store";
import router from "../router";
import moment from "moment";

function formatDateTime(time) {
  if (time === undefined) {
    return ""
  }
  return moment(time).utc(true).format("YYYY-MM-DD HH:mm:ss")
}

export default {
  name: 'BatchList',
  data: function () {
    return {
      filter: {page: 1, id: "", carNumber: "", holderInn: "", my: false},
      insurerMap: {
        "alfa": "Альфа",
        "renaissance": "Ренессанс",
      },
    }
  },
  computed: {
    batches: function () {
      let list = []
      for (const key in store.getters.batchesData.data) {
        let val = store.getters.batchesData.data[key]

        val.createdAtFmt = formatDateTime(val.createdAt)
        list.push(val)
      }
      return list
    },
    meta: function () {
      return store.getters.batchesData.meta
    }
  },
  created: function () {
    this.filter = store.getters.batchesData.filter
    store.commit('updateBatches', this.filter)
  },
  methods: {
    setPage(val) {
      this.filter.page = val
      this.filterBatches()
    },
    humanizeInsurer(row, column, status) {
      return this.insurerMap[status]
    },
    filterBatches() {
      this.filter.carNumber = this.filter.carNumber.trim()
      store.commit('updateBatches', this.filter)
    },
    createBatch() {
      router.push({name: 'BatchCreate'})
    },
    navigateBatch(batch) {
      router.push({name: 'BatchView', params: {id: batch.id}})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.info-item {
  margin-bottom: 18px;
}
</style>
