<template>
  <div>
    <div v-loading="loading">
      <h2><span v-if="id===''">Новая карта</span><span v-else>Карта {{ this.form.id }}</span></h2>
      <el-button @click="navigateToList()">К списку</el-button>
      <br>
      <br>
      <el-alert type="error" v-if="this.error">{{ this.error }}</el-alert>
      <br/>
      <br/>
      <el-row :gutter="10">
        <el-col>
          <div id="holder">
            <el-form ref="form" :rules="rules" label-position="right" label-width="250px"
                     :model="form">
              <el-card>
                <template #header>Карта</template>
                <el-row>
                  <el-col span="12">
                    <el-form-item  v-if="this.id===''" label="Номер карты" prop="id">
                      <el-input v-if="this.id===''" placeholder="" v-model="form.id"></el-input>
                    </el-form-item>
                    <el-form-item label="ID контрагента (наш)" prop="contragentId">
                      <el-input placeholder="" v-model="form.contragentId"></el-input>
                    </el-form-item>
                    <el-form-item label="Почта" prop="mail">
                      <el-input placeholder="" v-model="form.mail"></el-input>
                    </el-form-item>
                    <el-form-item label="ИНН собственника авто" prop="orgInn">
                      <el-input placeholder="" v-model="form.orgInn"></el-input>
                    </el-form-item>
                    <el-form-item label="Организация" prop="orgName">
                      <el-input placeholder="" v-model="form.orgName"></el-input>
                    </el-form-item>
                    <el-form-item label="Гос номер" prop="govNumber">
                      <el-input placeholder="" v-model="form.govNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="VIN" prop="vin">
                      <el-input placeholder="" v-model="form.vin"></el-input>
                    </el-form-item>
                    <el-form-item label="Карта активна" prop="bool">
                      <el-radio v-model="form.kaskoFuelActive" :label="true">Да</el-radio>
                      <el-radio v-model="form.kaskoFuelActive" :label="false">Нет</el-radio>
                    </el-form-item>
                  </el-col>

                </el-row>
              </el-card>
            </el-form>
          </div>
        </el-col>
      </el-row>
      <br>
      <br>
      <el-button type="primary" @click="submitForm()" :disabled="this.loading">
        <span v-if="this.id===''">Создать карту</span>
        <span v-else>Обновить карту</span>
      </el-button>
    </div>
  </div>
</template>

<script>

import store from "../../store";
import router from "@/router";
import axiosApiInstance from "../../axios";

function prepareNumber(str) {
  let list = {
    'a': 'а',
    'b': 'в',
    'e': 'е',
    'k': 'к',
    'm': 'м',
    'h': 'н',
    'o': 'о',
    'p': 'р',
    'c': 'с',
    't': 'т',
    'y': 'у',
    'x': 'х',
  }
  str = str.toLowerCase().trim()
  for (const x in list) {
    str = str.replaceAll(x, list[x]);
  }
  return str
}

const regexpList = [
  new RegExp(`^([авекномрстух]{1,3}\\d{3}[авекномрстух]{2})([1279]?\\d{2})$`, 'm'),
  new RegExp(`^(\\d{3,4}[авекномрстух]{1,2})([1279]?\\d{2})$`, 'm'),
  new RegExp(`^([авекномрстух]\\d{4})([1279]?\\d{2})$`, 'm'),
  new RegExp(`^([авекномрстух]{2}\\d{3})([1279]?\\d{2})$`, 'm'),
  new RegExp(`^([авекномрстух]{2}\\d{4})([1279]?\\d{2})$`, 'm'),
]

function validateNumber(number) {
  for (const key in regexpList) {
    if (regexpList[key].exec(number) !== null) {
      return true
    }
  }
  return false
}

const govNumberValidator = (rule, value, callback) => {

  if (value === undefined) {
    console.log("is undefined")
  }  else if (value.length > 0) {
    if (!validateNumber(prepareNumber(value))) {
      callback(new Error('Недопустимый формат номера'))
      return
    }
  }
  callback()
}

export default {
  name: 'CardEdit',
  data: function () {
    let requiredValidator = {required: true, message: "это поле обязательно"}
    let form = {
      contragentId: "",
      mail: "",
      govNumber: "",
      id: "",
      kaskoFuelActive: "",
      orgInn: "",
      orgName: "",
      vin: "",
    };

    return {
      id: "",
      loading: false,
      errorMessage: "",
      error: "",
      emptyForm: {...form},
      form: form,
      rules: {
        orgInn: [
          {required: true, pattern: /^\d{10,12}$/, trigger: "blur", "message": "необходимо указать 10-12 цифр"}
        ],
        mail: [
          {required: true, type: "email", trigger: "blur", "message": "недопустимый формат email"}
        ],
        string: [
          {pattern: /^[а-яА-Я0-9"\s-]+$/, trigger: "blur", "message": "поле содержит недопустимые символы"}
        ],
        vin: [
          {pattern: /^.+$/, trigger: "blur", "message": "неверный формат vin"}
        ],
        id: [
          requiredValidator,
          { min: 10, max: 10, message: 'длинна должна быть 10 символов', trigger: 'blur' },
        ],
        contragentId: [
          requiredValidator,
          { min: 32, max: 32, message: 'длинна должна быть 32 символа', trigger: 'blur' },
        ],
        orgName: [
          requiredValidator,
        ],
        govNumber: [
          {validator: govNumberValidator, trigger: "blur"}
        ],
      },
    }
  },
  methods: {
    submitForm() {
      this.loading = true
      let forms = ["form"]


      if (typeof this.form.govNumber === 'undefined' && typeof this.form.vin === 'undefined') {
        this.error = "Одно из полей (Гос номер или VIN) обязательно к заполнению"
        this.loading = false
        return
      }

      if (typeof this.form.govNumber !== 'undefined') {
        this.form.govNumber = prepareNumber(this.form.govNumber)
      }


      for (const key in forms) {
        this.$refs[forms[key]].validate((valid) => {
          if (valid) {
            this.createCard()
          } else {
            console.log("Ошибка валидации")
            this.loading = false
          }
        })
      }
    },
    createCard() {
      let data = this.form


      let action = 'createCard';
      if (this.id !== "") {
        action = 'updateCard'
      }

      store.commit(action, {
        id: this.id,
        data: data,
        onSuccess: (id) => {
          console.log(action + " success id:", id)
          router.push({name: 'CardView', params: {id: id}})
          this.loading = false
        },
        onError: (e) => {
          if (e.message) {
            this.error = "Ошибка создания " + e.message
          } else {
            this.error = "Ошибка создания " + e.errors[0].detail
          }

          this.loading = false
        }
      })
    },
    loadCard(id) {
      if (id !== "" && id !== undefined) {
        axiosApiInstance.get(process.env.VUE_APP_KASKO_SERVICE_URL + "/api/v1/fuel/cards/"  + id)
            .then((response) => {
              console.log(response.data.data)
              this.form = response.data.data
            }).catch(error => {
          console.log("view error ", error)
          alert("Ошибка получения данных карты")
        });
      }
    },
    navigateToList() {
      router.push({name: 'CardList'})
    },
  },
  mounted() {
    let id = this.$router.currentRoute.value.params.id
    if (id === undefined) {
      return
    }
    this.loadCard(id)
    this.id = id
  }
}
</script>

<style scoped>

</style>
