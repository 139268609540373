<template>
  <div>
    <div v-loading="loading">
      <h2>Реестр ОСАГО - <span v-if="id===''">Новый полис</span><span v-else>Полис {{ this.form.govNumber }}</span></h2>
      <el-button @click="navigateToList()">К списку</el-button>
      <el-button v-if="id===''" @click="clearOfferRequest">Очистить</el-button>
      <el-button type="primary" @click="submitForm()" :disabled="this.loading">
        <span v-if="this.form.id===''">Создать полис</span>
        <span v-else>Обновить полис</span>
      </el-button>
      <el-alert type="error" v-if="this.error">{{ this.error }}</el-alert>
      <br/>
      <br/>
      <el-row :gutter="10">
        <el-col>

          <div id="holder">
            <el-form ref="form" :rules="rules" label-position="right" label-width="250px"
                     :model="form">
              <el-card>
                <template #header>Полис</template>
                <el-row>
                  <el-col span="12">
                    <el-form-item label="Страховая компания" prop="string">
                      <el-input placeholder="альфастрахование" v-model="form.insurerName"></el-input>
                    </el-form-item>
                    <el-form-item label="Период действия полиса" prop="dateRange">
                      <el-date-picker start-placeholder="2022-01-02" end-placeholder="2023-01-02" type="daterange"
                                      size="small" @blur="dateRangeBlur" @change="dateRangeChange"
                                      v-model="form.dateRange"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="Серия полиса" prop="series">
                      <el-input placeholder="XXX" v-model="form.series"></el-input>
                    </el-form-item>
                    <el-form-item label="Номер полиса" prop="number">
                      <el-input placeholder="1234567890" v-model="form.number"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col span="12">
                    <el-form-item label="Премия (руб)" prop="premium">
                      <el-input placeholder="10100.99" v-model="form.premium"></el-input>
                    </el-form-item>
                    <el-form-item label="КБМ" prop="kbm">
                      <el-input placeholder="1.20" v-model="form.kbm"></el-input>
                    </el-form-item>
                    <el-form-item label="Регион использования" prop="regionOfUse">
                      <el-input placeholder="Архангельская область" v-model="form.regionOfUse"></el-input>
                    </el-form-item>
                    <el-form-item label="Город использования" prop="cityOfUse">
                      <el-input placeholder="Москва" v-model="form.cityOfUse"></el-input>
                    </el-form-item>
                    <el-form-item label="Договор полиса активен" prop="bool">
                      <el-radio v-model="form.isActive" :label="true">Да</el-radio>
                      <el-radio v-model="form.isActive" :label="false">Нет</el-radio>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
              <br/>
              <el-card>
                <template #header>Стороны контракта</template>
                <el-row>
                  <el-col span="12">
                    <el-form-item label="Тип владельца" prop="required">
                      <el-select-v2
                          v-model="form.ownerType"
                          :options="partyType"
                          placeholder="Выбрать тип"
                      />
                    </el-form-item>
                    <el-form-item label="ИНН владельца" prop="ownerINN">
                      <el-input placeholder="590201001" v-model="form.ownerINN"></el-input>
                    </el-form-item>
                    <el-form-item label="Наименование владельца" prop="name">
                      <el-input placeholder="ООО САТУРН-Р" v-model="form.ownerName"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col span="12">
                    <el-form-item label="Тип страхователя" prop="required">
                      <el-select-v2
                          v-model="form.holderType"
                          :options="partyType"
                          placeholder="Выбрать тип"
                      />
                    </el-form-item>
                    <el-form-item label="ИНН страхователя" prop="holderINN">
                      <el-input placeholder="590201001" v-model="form.holderINN"></el-input>
                    </el-form-item>
                    <el-form-item label="Наименование страователя" prop="name">
                      <el-input placeholder="ООО САТУРН-Р" v-model="form.holderName"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>


              </el-card>
              <br/>
              <el-card>
                <template #header>Авто</template>
                <el-row>
                  <el-col span="12">
                    <el-form-item label="Категория транспортного средства" prop="category">
                      <el-select-v2
                          v-model="form.category"
                          :options="carCategories"
                          placeholder="Выбрать тип"
                      />
                    </el-form-item>
                    <el-form-item label="VIN" prop="vin">
                      <el-input placeholder="XTA212140D2125175" v-model="form.vin"></el-input>
                    </el-form-item>
                    <el-form-item label="Госномер транспортного средства" prop="govNumber">
                      <el-input placeholder="К925УЕ22" v-model="form.govNumber">
                      </el-input>
                    </el-form-item>
                    <el-form-item label="Год производства" prop="year">
                      <el-input placeholder="2002" v-model="form.year"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col span="12">
                    <el-form-item label="Мощность (лс)" prop="power">
                      <el-input placeholder="100" v-model="form.power"></el-input>
                    </el-form-item>
                    <el-form-item label="Производитель" prop="maker">
                      <el-input placeholder="Lada" v-model="form.maker"></el-input>
                    </el-form-item>
                    <el-form-item label="Модель" prop="model">
                      <el-input placeholder="212140" v-model="form.model"></el-input>
                    </el-form-item>
                    <el-form-item label="Прицеп" prop="haveTrailer">
                      <el-radio v-model="form.haveTrailer" :label="true">Есть</el-radio>
                      <el-radio v-model="form.haveTrailer" :label="false">Нет</el-radio>
                    </el-form-item>
                  </el-col>
                </el-row>


              </el-card>
              <br/>
              <el-card>
                <template #header>Расширенные данные</template>
                <el-row>
                  <el-col span="12">
                    <el-form-item label="Номер кузова" prop="bodyNumber">
                      <el-input placeholder="1234556789" v-model="form.bodyNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="Номер шасси" prop="chassisNumber">
                      <el-input placeholder="1234556789" v-model="form.chassisNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="Цель использования" prop="targetOfUsage">
                      <el-select-v2
                          v-model="form.targetOfUsage"
                          :options="targetOfUsageCategories"
                      />
                    </el-form-item>
                    <el-form-item label="Максимум пассажиров" prop="maxPassengers">
                      <el-input placeholder="11" v-model="form.maxPassengers"></el-input>
                    </el-form-item>
                    <el-form-item label="Обьем двигателя, куб. см" prop="volume">
                      <el-input placeholder="100000" v-model="form.volume"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col span="12">
                    <el-form-item label="Максимальный вес в кг" prop="weight">
                      <el-input placeholder="10000" v-model="form.weight"></el-input>
                    </el-form-item>
                    <el-form-item label="Цвет" prop="color">
                      <el-input placeholder="Белый" v-model="form.color"></el-input>
                    </el-form-item>
                    <el-form-item label="Тип машины" prop="vehicleType">
                      <el-input placeholder="Грузовик" v-model="form.vehicleType"></el-input>
                    </el-form-item>
                    <el-form-item label="Положение рулевого колеса" prop="wheel">
                      <el-select-v2
                          v-model="form.wheel"
                          :options="wheelCategories"
                          placeholder="Выбрать тип"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>


              </el-card>
            </el-form>
          </div>
        </el-col>
      </el-row>
      <br/>

      <br/>
      <el-alert type="error" v-if="errorMessage" style="text-align: left" show-icon title="Ошибка запроса офферов"
                :description="errorMessage"></el-alert>
      <el-alert type="error" v-if="this.error">{{ this.error }}</el-alert>
      <br/>

      <el-button @click="history.back()">К списку</el-button>
      <el-button v-if="id===''" @click="clearOfferRequest">Очистить</el-button>
      <el-button type="primary" @click="submitForm()" :disabled="this.loading">
        <span v-if="this.form.id===''">Создать полис</span>
        <span v-else>Обновить полис</span>
      </el-button>
    </div>
  </div>
</template>

<script>

import store from "../../../store";
import router from '../../../router/index.js'
import moment from "moment";
import axiosApiInstance from "../../../axios";


function prepareNumber(str) {
  let list = {
    'a': 'а',
    'b': 'в',
    'e': 'е',
    'k': 'к',
    'm': 'м',
    'h': 'н',
    'o': 'о',
    'p': 'р',
    'c': 'с',
    't': 'т',
    'y': 'у',
    'x': 'х',
  }
  str = str.toLowerCase().trim()
  for (const x in list) {
    str = str.replaceAll(x, list[x]);
  }
  return str
}

const regexpList = [
  new RegExp(`^([авекномрстух]{1,3}\\d{3}[авекномрстух]{2})([179]?\\d{2})$`, 'm'),
  new RegExp(`^(\\d{3,4}[авекномрстух]{1,2})([179]?\\d{2})$`, 'm'),
  new RegExp(`^([авекномрстух]\\d{4})([179]?\\d{2})$`, 'm'),
  new RegExp(`^([авекномрстух]{2}\\d{3})([179]?\\d{2})$`, 'm'),
  new RegExp(`^([авекномрстух]{2}\\d{4})([179]?\\d{2})$`, 'm'),
]

function validateNumber(number) {
  console.log(number)
  for (const key in regexpList) {
    if (regexpList[key].exec(number) !== null) {
      return true
    }
  }
  return false
}

const govNumberValidator = (rule, value, callback) => {
  if (!validateNumber(prepareNumber(value))) {
    callback(new Error('Недопустимый формат номера'))
    return
  }
  callback()
}

export default {
  name: 'OsagoRegistryPolicyEdit',
  data: function () {
    let requiredValidator = {required: true, message: "это поле обязательно"}
    let from = moment().utc()
    let to = from.clone().add(1, 'year').add(-1, 'second')
    let form = {
      ownerINN: "",
      ownerName: "",
      ownerType: "LEGAL",
      insurerName: "",
      holderType: "LEGAL",
      holderName: "",
      holderINN: "",
      vin: "",
      bodyNumber: "",
      chassisNumber: "",
      key: 0,
      id: "",
      idType: "",
      maker: "",
      model: "",
      category: "",
      year: "",
      power: "",
      govNumber: "",
      targetOfUsage: "over",
      maxPassengers: 0,
      weight: "",
      haveTrailer: false,
      dateRange: [from, to],
      startDate: 0,
      endDate: 0,
      isActive: true,
      series: "",
      number: "",
      premium: "",
      kbm: "",
      regionOfUse: "",
      cityOfUse: "",
      vehicleType: "",
      wheel: "left",
      volume: "",
      color: ""
    };

    return {
      id: "",
      loading: false,
      errorMessage: "",
      error: "",
      emptyForm: {...form},
      form: form,
      targetOfUsageCategories: [
        {value: "over", label: "Прочие"},
        {value: "personal", label: "Личные"},
        {value: "taxi", label: "Такси"},
        {value: "learning", label: "Учебная езда"},
        {value: "special", label: "Дорожные и специальные ТС"},
        {value: "passenger", label: "Регулярные/по заказам пассажирские перевозки"},
        {value: "danger", label: "Перевозка опасных и легко воспламеняющихся грузов"},
        {value: "rental", label: "Прокат/Краткосрочная аренда"},
        {value: "utility", label: "Экстренные и коммунальные службы"},
      ],
      carCategories: [
        {value: "A", label: "A"},
        {value: "B", label: "B"},
        {value: "C", label: "C"},
        {value: "D", label: "D"},
        {value: "E", label: "E"},
        {value: "Tm", label: "Tm"},
        {value: "Tb", label: "Tb"},
      ],
      wheelCategories: [
        {value: "", label: "Неизвестно"},
        {value: "left", label: "Левое"},
        {value: "right", label: "Правое"},
      ],
      partyType: [
        {value: "LEGAL", label: "Организация"},
        {value: "PERSON", label: "Физлицо"},
        {value: "UNKNOWN", label: "Неизвестно"},
      ],
      rules: {
        ownerINN: [
          {required: true, pattern: /^\d{10,12}$/, trigger: "blur", "message": "необходимо указать 10-12 цифр"}
        ],
        holderINN: [
          {required: true, pattern: /^\d{10,12}$/, trigger: "blur", "message": "необходимо указать 10-12 цифр"}
        ],
        string: [
          {pattern: /^[а-яА-Я0-9"\s-]+$/, trigger: "blur", "message": "поле содержит недопустимые символы"}
        ],
        vin: [
          requiredValidator,
        ],
        maker: [
          requiredValidator,
          {required: true, pattern: /^[a-zA-Zа-яА-Я0-9 -]+$/, trigger: "blur", "message": "допустимы символы a-z"}
        ],
        model: [
          requiredValidator,
          {
            required: true,
            pattern: /^[a-zA-Z0-9А-Яа-я0-9 -]+$/,
            trigger: "blur",
            "message": "допустимы символы a-zA-Z0-9"
          }
        ],
        category: [
          requiredValidator,
          {required: true, pattern: /^[a-zA-Z]{1,2}$/, trigger: "blur", "message": "допустимы 1-2 символа a-zA-Z"}
        ],
        year: [
          requiredValidator,
          {required: true, pattern: /^\d{4}$/, trigger: "blur", "message": "формат - 4 цифры"}
        ],
        power: [
          requiredValidator,
          {required: true, pattern: /^\d+(\.\d{1,2})*$/, trigger: "blur", "message": "формат 1.21"}
        ],
        kbm: [
          {pattern: /^\d(\.\d{1,2})*$/, trigger: "blur", "message": "формат 1.21"}
        ],
        premium: [
          {pattern: /^\d+(\.\d{1,2})*$/, trigger: "blur", "message": "формат 1.21"}
        ],
        govNumber: [
          {required: true, validator: govNumberValidator, trigger: "blur"}
        ],
        color: [
          {pattern: /^[а-яА-Я-]+$/, trigger: "blur", "message": "допустимы символы а-яА-Я и тире"}
        ],
        series: [
          {pattern: /^[А-Я]{3}$/, trigger: "blur", message: "формат - 3 русские буквы в верхнем регистре"}
        ],
        number: [
          {pattern: /^\d{10}$/, trigger: "blur", message: "формат - 10 цифр"}
        ],
        weight: [
          {pattern: /^\d{1,10}$/, trigger: "blur", "message": "формат 1-10 цифр"}
        ],
        regionOfUse: [
          {pattern: /^[а-яА-Я- ]+$/, trigger: "blur", "message": "допустимы символы а-яА-Я тире и пробелы"}
        ],
        cityOfUse: [
          {pattern: /^[а-яА-Я- ]+$/, trigger: "blur", "message": "допустимы символы а-яА-Я тире и пробелы"}
        ],
        vehicleType: [
          {pattern: /^[а-яА-Я- ]+$/, trigger: "blur", "message": "допустимы символы а-яА-Я тире и пробелы"}
        ],
        targetOfUsage: [
          {pattern: /^[a-z]+$/, trigger: "blur", "message": "разрешена только латиница"}
        ],
        chassisNumber: [
          {pattern: /^[\dа-яa-zA-ZА-Я ]+$/, trigger: "blur", "message": "допустимы буквы, цифры и пробелы"}
        ],
        bodyNumber: [
          {pattern: /^[\dа-яa-zA-ZА-Я ]+$/, trigger: "blur", "message": "допустимы буквы, цифры и пробелы"}
        ],
        maxPassengers: [
          {pattern: /^\d+$/, trigger: "blur", "message": "разрешены только цифры"}
        ],
        haveTrailer: [
          requiredValidator,
        ],
        volume: [
          {pattern: /^\d+$/, trigger: "blur", "message": "разрешены только цифры"}
        ],
      },
    }
  },
  methods: {
    formatDate(time) {
      if (time === undefined) {
        return ""
      }
      return moment(time).utc(true).format("YYYY-MM-DD")
    },
    submitForm() {
      this.loading = true
      let forms = ["form"]
      let ok = true
      this.form.govNumber = prepareNumber(this.form.govNumber)
      for (const key in forms) {
        this.$refs[forms[key]].validate((valid) => {
          console.log(forms[key], valid)
          if (valid) {
            ok = ok && true
          } else {
            ok = ok && false
            return false
          }
        })
      }
      if (ok) {
        this.createOsagoPolicy()
      } else {
        console.log("Ошибка валидации")
        this.loading = false
      }
    },
    dateRangeChange() {
      let start = moment(this.form.dateRange[0].valueOf()).utc(true).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      })

      let end = moment(this.form.dateRange[1].valueOf()).utc(true).set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0
      })
      if (start.isSameOrAfter(end)) {
        alert("Дата окончания раньше или равна дате начала")
        this.loading = false
        return
      }
      this.form.startDate = start.valueOf()
      this.form.endDate = end.valueOf()
      console.log("change", start, end)
    },
    createOsagoPolicy() {
      let data = this.form
      if (data.dateRange === undefined || data.dateRange === "" || data.dateRange.length !== 2) {
        return
      }

      data.year = parseInt(data.year, 10)
      if (data.maxPassengers === "") {
        delete data.maxPassengers
      }
      if (data.targetOfUsage === "") {
        delete data.targetOfUsage
      }
      if (data.weight === "") {
        delete data.weight
      } else {
        console.log("parse weight " + data.weight)
        data.weight = parseInt(data.weight, 10)
      }
      if (data.maxPassengers === 0) {
        delete data.maxPassengers
      } else {
        data.maxPassengers = parseInt(data.maxPassengers, 10)
      }
      if (data.volume === "") {
        data.volume = 0
      } else {
        data.volume = parseInt(data.volume, 10)
      }

      let action = 'createOsagoRegistryPolicy';
      if (this.id !== "") {
        action = 'updateOsagoRegistryPolicy'
      }

      console.log(data.startDate, data.endDate);

      store.commit(action, {
        id: this.id,
        data: data,
        onSuccess: (id) => {
          console.log(action + " success id:", id)
          router.push({name: 'OsagoRegistryPolicyView', params: {id: id}})
          this.loading = false
        },
        onError: (e) => {
          this.error = "Ошибка создания " + e
          this.loading = false
        }
      })
    },
    clearOfferRequest() {
      this.form = this.emptyForm
    },
    loadPolicy(id) {
      if (id !== "" && id !== undefined) {
        axiosApiInstance.get(process.env.VUE_APP_OSAGO_REGISTRY_SERVICE_URL + "/api/v1/osago/" + id)
            .then((response) => {
              console.log(response.data)
              this.form = response.data
              this.form.dateRange = [
                moment(this.form.startDate).utc(true).toDate() ,
                moment(this.form.endDate).utc(true).toDate()
              ]
            }).catch(error => {
          console.log("view error ", error)
          alert("Ошибка получения данных полиса")
          router.push({name: 'OsagoRegistryPolicyView', params: {id: id}})
        });
      }
    },
    navigateToList() {
      router.push({name: 'OsagoRegistryPoliciesList'})
    },
    loadFromSearchResult() {

      let data = this.$store.getters.wizardSearchResult
      if (data.cars.length < 1) {
        return
      }
      this.form = {
        ...this.form,
        ownerINN: data.owner.inn,
        ownerName: data.owner.name,
        holderName: data.holder.name,
        holderINN: data.holder.inn,
      }
      let car = data.cars[0]

      let startTime, endTime
      if (car.dateStart) {
        startTime = moment(car.dateStart).add(1, 'd')
        endTime = startTime.clone().add(365, 'd').add(-1, 's')
      } else {
        startTime = moment().startOf('day')
        endTime = startTime.clone().add(365, 'd').add(-1, 's')
      }


      this.form = {
        ...this.form,
        vin: car.vin,
        maker: car.maker,
        model: car.model,
        category: car.category,
        year: car.year,
        power: car.power,
        govNumber: car.govNumber,
        maxPassengers: car.maxPassengers,
        weight: car.weight,
        haveTrailer: car.haveTrailer,
        startDate: startTime.valueOf(),
        endDate: endTime.valueOf(),
        dateRange: [this.formatDate(startTime.valueOf()), this.formatDate(endTime.valueOf())],
      }
      console.log("loadFromSearchResult", startTime, endTime, this.form)

      if (car.maxPassengers !== undefined) {
        this.form.maxPassengers = car.maxPassengers
      }
      if (car.regionOfUse !== undefined) {
        this.form.regionOfUse = car.regionOfUse
      }
      if (car.cityOfUse !== undefined) {
        this.form.cityOfUse = car.cityOfUse
      }
      if (car.volume !== undefined) {
        this.form.volume = car.volume
      }
      if (car.maxWeight !== undefined) {
        this.form.weight = car.maxWeight
      }
      if (car.wheel !== undefined) {
        this.form.wheel = car.wheel
      }
      if (car.chassisNumber !== undefined) {
        this.form.chassisNumber = car.chassisNumber
      }
      if (car.bodyNumber !== undefined) {
        this.form.bodyNumber = car.bodyNumber
      }
      if (car.color !== undefined) {
        this.form.color = car.color
      }
    }
  },
  mounted() {
    if (this.$router.currentRoute.value.query.fromSearch) {
      this.loadFromSearchResult()
      return;
    }
    let id = this.$router.currentRoute.value.params.id
    if (id === undefined) {
      return
    }
    this.loadPolicy(id)
    this.id = id
  }
}
</script>

<style scoped>

</style>
