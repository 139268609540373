import VueAxios from 'vue-axios'
import router from './router/index.js'
import App from "./App";
import {createApp} from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axiosApiInstance from './axios.js'
import store from "./store";
import KeyCloakService from "./keycloak";

const moment = require('moment-timezone');
moment.tz.setDefault("Europe/Moscow");

const renderApp = (d) => {
    console.log(d)
    createApp(App)
        .use(VueAxios, axiosApiInstance)
        .use(router)
        .use(store)
        .use(ElementPlus)
        .mount('#app')
}

KeyCloakService.CallLogin(renderApp);