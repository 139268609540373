<template>
  <div class="data">
    <el-progress v-if="reqCheck" indeterminate="indeterminate" :percentage="reqCheckPercentage"
                 style="margin-bottom: 20px"
                 :show-text="false"/>
    <div v-loading="loading">
      <h3>{{ process.carNumbers }} {{ ownerTitle }}</h3>
      <div v-if="process.id"  >
          <div v-if="process.status==='rejected'">
            Клиент отказался по причине: {{ humanizeReject(process) }}
          </div>
          <div v-if="process.status==='failed'">
            Возникла проблема поиска осаго: {{ humanizeProcessFail(process) }}
          </div>
          <div v-if="process.errorCode===50010">
            У машины есть полис заканчивающийся далеко в будущем: {{ formatDate(process.policyStartAt) }}
          </div>
          <div v-else-if="process.status==='completed' && process.offers.length<1">
            Нет страховых предложений
          </div>
          <div v-else-if="process.status!=='failed' && process.status!=='rejected'">
            <h2>Предложения страховых</h2>
            <div v-for="offer in process.offers" v-bind:key="offer.id" style="padding-bottom: 20px; margin-top:5px">
              <el-row>
                <el-col :span="3" :xs="6" :s="6" :md="2" :lg="2">
                  <div>
                    <span style="font-size: 20px" v-if="offer.premium>0">
                      {{ offer.premium }}
                    </span>
                  </div>
                </el-col>
                <el-col :span="10" :xs="7" :s="7" :md="7" :lg="7">
                  <el-row>
                    <el-col>
                      {{ humanizeInsurer(offer.insurer) }} истекает {{ offerLastDate(offer) }}

                    </el-col>
                  </el-row>
                  <div><sub>{{ offer.id }}</sub></div>
                  <div v-if="offer.insurerProcessId!==''"><sub>Индентификатор в страховой {{ offer.insurerProcessId }}</sub></div>
                  <div v-if="offer.haveCalculationFile"><a title="Скачать детали калькуляции" href="#"
                                                           @click="downloadFile(offer,'calculation')">
                    детали рассчета
                  </a></div>
                </el-col>
                <el-col :span="10" lg="6">
                  <el-row v-if="offer.status!=='completed'">
                    <el-col>
                      <div v-if="offer.status==='failed'">
                        <div v-if="isRejected(offer)">
                          {{ humanizeOfferFail(offer) }}
                        </div>
                        <div v-else>
                          <el-button type="danger" plain text
                                     @click="displayError(offer)">
                            {{ humanizeOfferStatus(offer.status) + " " + humanizeOfferFail(offer) }}
                          </el-button>
                        </div>
                      </div>
                      <div v-else>{{ humanizeOfferStatus(offer.status) }}</div>
                    </el-col>
                  </el-row>
                  <el-row v-else-if="this.policy.id === undefined">
                    <el-button
                        v-if="offer.status==='completed'"
                        :disabled="offerDisabled(offer)"
                        type="success" size="small"
                        @click="createPolicyByOffer(offer)">
                    <span v-if="offerDisabled(offer)">
                      Предложение устарело
                    </span>
                      <span v-else>
                      Оформить в {{ humanizeInsurer(offer.insurer) }}
                    </span>
                    </el-button>
                  </el-row>
                  <el-row v-else>
                    <el-button v-if="this.policy.offerId===offer.id" @click="navigateToPolicy(policy)" class="button"
                               size="small">Перейти к полису
                    </el-button>
                  </el-row>
                </el-col>
                <el-col :span="6">

                </el-col>
              </el-row>
            </div>

          </div>
          <div v-if="isStopped(process)">
            <h2>Действия</h2>
            <el-button size="small"
                       @click="recalc(process)">Перерасчет
            </el-button>
            <el-button type="warning" size="small" plain
                       :disabled="this.process.rejectType==='over' && this.process.rejectComment!==undefined && this.process.rejectComment.length<1"
                       @click="rejectVisible=true">Клиент отказался от предложений
            </el-button>
          </div>
          <!--Данные заявки-->
          <div>
            <h2>Данные заявки</h2>
            <el-row :gutter="5">
              <el-col :span="4">ID</el-col>
              <el-col :span="19">{{ process.id }}</el-col>
            </el-row>
            <el-row :gutter="5">
              <el-col :span="4">Дата</el-col>
              <el-col :span="19">{{ formatDateTime(process.createdAt) }}</el-col>
            </el-row>
            <el-row :gutter="5">
              <el-col :span="4">Период действия</el-col>
              <el-col :span="19">{{ formatDate(process.policyStartAt) }} - {{
                  formatDate(process.policyEndAt)
                }}
              </el-col>
            </el-row>
            <el-row :gutter="5">
              <el-col :span="4">Страховые</el-col>
              <el-col :span="19">{{ humanizeInsurers(this.dataOsago.insurers).join(", ") }}</el-col>
            </el-row>
            <el-row :gutter="5">
              <el-col :span="4">Контрагент</el-col>
              <el-col :span="19">{{ this.contId }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div v-if="this.dataOsago.holder.addressFact!==undefined">
                  <h5>Страхователь</h5>
                  <el-row :gutter="5">
                    <el-col :span="4">Название</el-col>
                    <el-col :span="19">{{ this.dataOsago.holder.name }}</el-col>
                  </el-row>
                  <el-row :gutter="5">
                    <el-col :span="4">Инн</el-col>
                    <el-col :span="19">{{ this.dataOsago.holder.inn }}</el-col>
                  </el-row>
                  <el-row :gutter="5">
                    <el-col :span="4">КПП</el-col>
                    <el-col :span="19">{{ this.dataOsago.holder.kpp }}</el-col>
                  </el-row>
                  <el-row :gutter="5">
                    <el-col :span="4">ОГРН</el-col>
                    <el-col :span="19">{{ this.dataOsago.holder.ogrn }}</el-col>
                  </el-row>
                  <el-row :gutter="5">
                    <el-col :span="4">Дата ОГРН</el-col>
                    <el-col :span="19">{{ this.dataOsago.holder.ogrnDate }}</el-col>
                  </el-row>
                  <el-row :gutter="5">
                    <el-col :span="4">ОКОПФ</el-col>
                    <el-col :span="19">{{ this.dataOsago.holder.opf }}</el-col>
                  </el-row>
                  <el-row :gutter="5">
                    <el-col :span="4">
                      <button @click="showHolderAddressFact=!showHolderAddressFact">Адрес</button>
                    </el-col>
                    <el-col :span="19">{{ this.dataOsago.holder.addressFact.full }}</el-col>
                  </el-row>
                  <div v-if="showHolderAddressFact">
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">КЛАДР региона</el-col>
                      <el-col :span="14">{{ this.dataOsago.holder.addressFact.regionKladrId }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Регион</el-col>
                      <el-col :span="14">{{ this.dataOsago.holder.addressFact.region }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Улица</el-col>
                      <el-col :span="14">{{ this.dataOsago.holder.addressFact.street }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">КЛАДР города</el-col>
                      <el-col :span="14">{{ this.dataOsago.holder.addressFact.cityKladr }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Город</el-col>
                      <el-col :span="14">{{ this.dataOsago.holder.addressFact.city }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">КЛАДР улицы</el-col>
                      <el-col :span="14">{{ this.dataOsago.holder.addressFact.streetKladr }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Индекс</el-col>
                      <el-col :span="14">{{ this.dataOsago.holder.addressFact.zip }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Страна</el-col>
                      <el-col :span="14">{{ this.dataOsago.holder.addressFact.country }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Квартира</el-col>
                      <el-col :span="14">{{ this.dataOsago.holder.addressFact.flat }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Дом</el-col>
                      <el-col :span="10">{{ this.dataOsago.holder.addressFact.house }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Здание</el-col>
                      <el-col :span="14">{{ this.dataOsago.holder.addressFact.building }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">КЛАДР</el-col>
                      <el-col :span="14">{{ this.dataOsago.holder.addressFact.kladrId }}</el-col>
                    </el-row>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div v-if="this.dataOsago.owner.addressFact!==undefined">
                  <h5>Владелец</h5>
                  <el-row :gutter="5">
                    <el-col :span="4">Название</el-col>
                    <el-col :span="19">{{ this.dataOsago.owner.name }}</el-col>
                  </el-row>
                  <el-row :gutter="5">
                    <el-col :span="4">Инн</el-col>
                    <el-col :span="19">{{ this.dataOsago.owner.inn }}</el-col>
                  </el-row>
                  <el-row :gutter="5">
                    <el-col :span="4">КПП</el-col>
                    <el-col :span="19">{{ this.dataOsago.owner.kpp }}</el-col>
                  </el-row>
                  <el-row :gutter="5">
                    <el-col :span="4">ОГРН</el-col>
                    <el-col :span="19">{{ this.dataOsago.owner.ogrn }}</el-col>
                  </el-row>
                  <el-row :gutter="5">
                    <el-col :span="4">Дата ОГРН</el-col>
                    <el-col :span="19">{{ this.dataOsago.owner.ogrnDate }}</el-col>
                  </el-row>
                  <el-row :gutter="5">
                    <el-col :span="4">ОКОПФ</el-col>
                    <el-col :span="19">{{ this.dataOsago.owner.opf }}</el-col>
                  </el-row>
                  <el-row :gutter="5">
                    <el-col :span="4">
                      <button @click="showOwnerAddressFact=!showOwnerAddressFact">Адрес</button>
                    </el-col>
                    <el-col :span="19">{{ this.dataOsago.owner.addressFact.full }}</el-col>
                  </el-row>
                  <div v-if="showOwnerAddressFact">
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">КЛАДР региона</el-col>
                      <el-col :span="14">{{ this.dataOsago.owner.addressFact.regionKladrId }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Регион</el-col>
                      <el-col :span="14">{{ this.dataOsago.owner.addressFact.region }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Улица</el-col>
                      <el-col :span="14">{{ this.dataOsago.owner.addressFact.street }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">КЛАДР города</el-col>
                      <el-col :span="14">{{ this.dataOsago.owner.addressFact.cityKladr }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Город</el-col>
                      <el-col :span="14">{{ this.dataOsago.owner.addressFact.city }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">КЛАДР улицы</el-col>
                      <el-col :span="14">{{ this.dataOsago.owner.addressFact.streetKladr }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Индекс</el-col>
                      <el-col :span="14">{{ this.dataOsago.owner.addressFact.zip }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Страна</el-col>
                      <el-col :span="14">{{ this.dataOsago.owner.addressFact.country }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Квартира</el-col>
                      <el-col :span="14">{{ this.dataOsago.owner.addressFact.flat }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Дом</el-col>
                      <el-col :span="14">{{ this.dataOsago.owner.addressFact.house }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">Здание</el-col>
                      <el-col :span="14">{{ this.dataOsago.owner.addressFact.building }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                      <el-col :span="10" :lg="5">КЛАДР</el-col>
                      <el-col :span="14">{{ this.dataOsago.owner.addressFact.kladrId }}</el-col>
                    </el-row>
                  </div>
                </div>
              </el-col>
            </el-row>
            <div v-if="this.dataOsago.cars!==undefined && this.dataOsago.cars.length>0">
              <hr/>
              <h5>Авто</h5>
              <div v-bind:key="car.id" v-for="car in this.dataOsago.cars">
                <el-row>
                  <el-col :span="12">
                    <div>
                      <el-row :gutter="5">
                        <el-col :span="5">Госномер</el-col>
                        <el-col :span="16">{{ car.govNumber }}</el-col>
                      </el-row>
                      <el-row :gutter="5">
                        <el-col :span="5">Категория</el-col>
                        <el-col :span="16">{{ car.category }}</el-col>
                      </el-row>
                      <el-row :gutter="5">
                        <el-col :span="5">VIN</el-col>
                        <el-col :span="16">{{ car.vin }}</el-col>
                      </el-row>
                      <el-row :gutter="5">
                        <el-col :span="5">Год производства</el-col>
                        <el-col :span="16">{{ car.year }}</el-col>
                      </el-row>
                      <el-row :gutter="5">
                        <el-col :span="5">Дата начала</el-col>
                        <el-col :span="16">{{ formatDate(car.dateStart) }}</el-col>
                      </el-row>
                      <el-row :gutter="5">
                        <el-col :span="5">Трейлер</el-col>
                        <el-col :span="16">{{ car.haveTrailer ? "Да" : "Нет" }}</el-col>
                      </el-row>
                      <el-row :gutter="5">
                        <el-col :span="5">Производитель</el-col>
                        <el-col :span="16">{{ car.maker }}</el-col>
                      </el-row>
                      <el-row :gutter="5">
                        <el-col :span="5">Модель</el-col>
                        <el-col :span="16">{{ car.model }}</el-col>
                      </el-row>
                      <el-row :gutter="5">
                        <el-col :span="5">Максимальный вес</el-col>
                        <el-col :span="16">{{ car.maxWeight }}</el-col>
                      </el-row>
                      <el-row :gutter="5">
                        <el-col :span="5">Мощность</el-col>
                        <el-col :span="16">{{ car.power }}</el-col>
                      </el-row>
                      <el-row :gutter="5">
                        <el-col :span="5">Регион использования</el-col>
                        <el-col :span="16">{{ car.regionOfUse }}</el-col>
                      </el-row>
                      <el-row :gutter="5">
                        <el-col :span="5">КЛАДР региона использования</el-col>
                        <el-col :span="16">{{ car.regionOfUseKladr }}</el-col>
                      </el-row>
                      <el-row :gutter="5">
                        <el-col :span="5">Город использования</el-col>
                        <el-col :span="16">{{ car.cityOfUse }}</el-col>
                      </el-row>
                      <el-row :gutter="5">
                        <el-col :span="5">КЛАДР региона использования</el-col>
                        <el-col :span="16">{{ car.cityKladr }}</el-col>
                      </el-row>

                    </div>
                  </el-col>
                  <el-col :span="12">
                    <el-tabs v-model="activeCarTab">
                      <el-tab-pane label="ПТС" v-if="car.ptsNumber" name="pts">
                        <el-row :gutter="5">
                          <el-col :span="5">Номер</el-col>
                          <el-col :span="19">{{ car.ptsNumber }}</el-col>
                        </el-row>
                        <el-row :gutter="5">
                          <el-col :span="5">Серия</el-col>
                          <el-col :span="19">{{ car.ptsSeries }}</el-col>
                        </el-row>
                        <el-row :gutter="5">
                          <el-col :span="5">Дата</el-col>
                          <el-col :span="19">{{ formatDate(car.ptsDate) }}</el-col>
                        </el-row>
                      </el-tab-pane>
                      <el-tab-pane label="СТС" v-if="car.stsNumber" name="sts">
                        <el-row :gutter="5">
                          <el-col :span="5">Номер</el-col>
                          <el-col :span="19">{{ car.stsNumber }}</el-col>
                        </el-row>
                        <el-row :gutter="5">
                          <el-col :span="5">Серия</el-col>
                          <el-col :span="19">{{ car.stsSeries }}</el-col>
                        </el-row>
                        <el-row :gutter="5">
                          <el-col :span="5">Дата</el-col>
                          <el-col :span="19">{{ formatDate(car.stsDate) }}</el-col>
                        </el-row>
                      </el-tab-pane>
                      <el-tab-pane label="ПСМ" name="psm" v-if="car.psmNumber">
                        <el-row :gutter="5">
                          <el-col :span="5">Номер</el-col>
                          <el-col :span="19">{{ car.psmNumber }}</el-col>
                        </el-row>
                        <el-row :gutter="5">
                          <el-col :span="5">Серия</el-col>
                          <el-col :span="19">{{ car.psmSeries }}</el-col>
                        </el-row>
                        <el-row :gutter="5">
                          <el-col :span="5">Дата</el-col>
                          <el-col :span="19">{{ formatDate(car.psmDate) }}</el-col>
                        </el-row>
                      </el-tab-pane>
                      <el-tab-pane label="ЕПТС" name="epts" v-if="car.eptsNumber">
                        <el-row :gutter="5">
                          <el-col :span="5">Номер</el-col>
                          <el-col :span="19">{{ car.eptsNumber }}</el-col>
                        </el-row>
                        <el-row :gutter="5">
                          <el-col :span="5">Серия</el-col>
                          <el-col :span="19">{{ car.eptsSeries }}</el-col>
                        </el-row>
                        <el-row :gutter="5">
                          <el-col :span="5">Дата</el-col>
                          <el-col :span="19">{{ formatDate(car.eptsDate) }}</el-col>
                        </el-row>
                      </el-tab-pane>
                      <el-tab-pane label="Другой документ" name="over" v-if="car.overDocNumber">
                        <el-row :gutter="5">
                          <el-col :span="5">Номер</el-col>
                          <el-col :span="19">{{ car.overDocNumber }}</el-col>
                        </el-row>
                        <el-row :gutter="5">
                          <el-col :span="5">Серия</el-col>
                          <el-col :span="19">{{ car.overDocSeries }}</el-col>
                        </el-row>
                        <el-row :gutter="5">
                          <el-col :span="5">Дата</el-col>
                          <el-col :span="19">{{ formatDate(car.overDocDate) }}</el-col>
                        </el-row>
                      </el-tab-pane>
                    </el-tabs>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
      </div>
    </div>
    <el-dialog
        v-model="rejectVisible"
        title="Отказ клиента"
        width="30%"
    >
      <el-row :gutter="5">
        <el-col>
          <el-select style="width:100%" v-model="this.process.rejectType" placeholder="Причина отказа">
            <el-option
                v-for="item in this.rejectTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <br v-if="this.process.rejectType==='over'"/>
      <el-row :gutter="5" v-if="this.process.rejectType==='over'">
        <el-col>
          <el-input :rows="2" type="textarea" placeholder="Комментарий" :maxlength="2000"
                    v-model="this.process.rejectComment"/>
        </el-col>
        <el-col>
        </el-col>
      </el-row>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="rejectVisible = false">Отмена</el-button>
        <el-button type="primary" @click="rejectProcess()">Сохранить</el-button>
      </span>
      </template>
    </el-dialog>

  </div>
</template>
<style>

</style>
<script>


import store from "../store";
import axiosApiInstance from "../axios";
import router from "../router";
import moment from "moment";
import {ElMessageBox} from "element-plus";

export default {
  name: 'ProcessView',
  mounted() {
    this.loadProcess()
    this.checkInterval = setInterval(() => {
      if (this.reqCheck) {
        this.reqCheckPercentage -= 10
        if (this.reqCheckPercentage === 0) {
          this.loadProcess()
          this.reqCheckPercentage = 100
        }
      }
    }, 500);
  },
  unmounted() {
    if (this.checkInterval) {
      this.reqCheckPercentage = 100
      clearInterval(this.checkInterval)
    }
  },
  data: function () {
    return {
      rejectVisible: false,
      loading: true,
      reqCheck: false,
      showHolderAddressFact: false,
      showOwnerAddressFact: false,
      reqCheckPercentage: 100,
      process: {},
      rejectType: "",
      rejectTypeOptions: [
        {
          value: 'later',
          label: 'Еще рано, вернусь позже',
        },
        {
          value: 'found_cheaper',
          label: 'Нашел дешевле',
        },
        {
          value: 'non_actual',
          label: 'Уже продлил',
        },
        {
          value: 'over',
          label: 'Другое',
        },
      ],
      rejectComment: "",
      policy: {},
      dataOsago: {},
      contId: "",
      activeCarTab: "pts",
      activeTab: "offers",
      checkInterval: null,
      offerStatusMap: {
        "processing": "обработка",
        "failed": "ошибка",
        "timeout": "таймаут",
        "completed": "завершено"
      },
      insurerMap: {
        "alfa": "АльфаСтрахование",
        "renaissance": "Ренессанс",
        "elt_reso": "ЕЛТ:Ресо",
        "elt_renins": "ЕЛТ:Ренессанс",
        "elt_vsk": "ЕЛТ:ВСК",
        "elt_rgs": "ЕЛТ:Росгосстрах",
      },
      policyStatusMap: {
        "new": "Ожидает",
        "creating": "Создается",
        "created": "Создан",
        "create_failed": "Ошибка создания",
        "activating": "Активируется",
        "activated": "Активен",
        "activation_failed": "Ошибка активации"
      },
      rejectErrorCodes: [
        10005,
        10009,
        10017,
        10018,
        10019,
        10110,
        10114,
        10115,
        10116,
        10117,
        10118,
        10125,
        10130,
        10135,
        10300,
        52001,
        52002,
        52003,
        52004,
        52005,
        52006,
        50250,
        50251,
        50252,
        50253,
        50254,
        50255,
        50001,
        50002,
        50010,
        50103,
        50105
      ],
      errorMap: {
        10000: "Коды ошибок страхования ОСАГО",
        10001: "Ошибка запроса калькуляции",
        10005: "Ошибка валидации авто",
        10006: "Таймаут валидации авто",
        10008: "Ошибка калькуляции",
        10009: "Невозможно нормализовать адрес",
        10010: "Ошибка добавления страхователя",
        10011: "Ошибка результата калькуляции",
        10012: "Таймаут калькуляции",
        10013: "Ошибка введенных данных (по машине, по адресам)",
        10015: "Ошибка запроса КБМ",
        10016: "Ошибка разбора результата КБМ",
        10017: "Ограничение по КБМ",
        10018: "Проблема в данных страхователя",
        10019: "Проблема в даных владельца",
        10020: "Ошибка добавления владельца",
        10025: "Ошибка оформления проекта страховки",
        10026: "Таймаут оформления проекта страховки",
        10030: "Ошибка получения файла счета",
        10031: "Таймаут получения файла счета",
        10035: "Ошибка активации полиса",
        10036: "Таймаут активации полиса",
        10037: "Таймаут получения файла полиса",
        10040: "Ошибка получения полиса",
        10042: "Ошибка получения документов",
        10045: "Таймаут получения документов",
        10110: "Данные начала полиса находятся в прошлом",
        10114: "Неправильный формат VIN",
        10115: "Неправильный формат серии документа авто",
        10116: "Неправильный формат номера документа авто",
        10117: "Неправильная дата документа авто",
        10118: "Неправильный производитель авто",
        10125: "Неправильный год производства авто",
        10130: "Мощность авто должна быть в пределах 17-2000",
        10135: "Неправильный формат госномера авто",
        10300: "Невозможно оформить страховку",
        52001: "Неизвестный город отправления груза",
        52002: "Неизвестный город прибытия груза",
        52003: "Недопустимая сумма покрытия груза",
        52004: "Недопустимая дата начала начала перевозки",
        52005: "Недопустимый маршрут перевозки",
        52006: "Грузоотправитель не найден по ИНН в сервисе организаций",
        53100: "Коды ошибок связанные с контрагентами",
        53101: "Неизвестный контрагент",
        54100: "Коды ошибок связанные с балансом",
        54101: "Недостаточно средств на балансе контрагента",
        51101: "Ошибка создания записи предложения ОСАГО",
        50250: "Инн владельца не найден в полисе осаго",
        50251: "Организация владельца не найдена",
        50252: "Инн страхователя не найден в полисе осаго",
        50253: "Организация страхователя  не найдена",
        50254: "Владелец не юрлицо",
        50255: "Страхователь не юрлицо",
        50000: "Сервис страховок не доступен",
        50001: "Владелец в черном списке",
        50002: "Страхователь в черном списке",
        50010: "Есть полис на следующий год",
        50101: "Неизвестная ошибка",
        50103: "Нельзя оформить осаго (например такси или мопед)",
        50105: "Неизвестный формат госномера",
      }
    }
  },
  computed: {
    ownerTitle: function () {
      if (this.dataOsago.holder === undefined || this.dataOsago.holder.inn === undefined) {
        return ""
      }
      return this.dataOsago.holder.inn + " " + this.dataOsago.holder.name
    }
  },
  methods: {
    isRejected(data) {
      return this.rejectErrorCodes.includes(data.errorCode)
    },
    printError(data) {
      if (this.errorMap[data.errorCode] !== undefined) {
        let text = data.errorCode + ": " + this.errorMap[data.errorCode];
        if (data.error !== '') {
          text += '. ' + data.error;
        }
        return text;
      }
      return data.errorCode + ": " + data.error
    },
    humanizeOfferStatus(x) {
      return this.offerStatusMap[x]
    },
    humanizePolicyStatus(x) {
      return this.policyStatusMap[x]
    },
    displayError(offer) {
      ElMessageBox.alert(this.printError(offer), 'Ошибка при обработки заявки в ' + this.humanizeInsurer(offer.insurer), {
        confirmButtonText: 'Смириться'
      })
    },
    humanizeInsurer(status) {
      if (this.insurerMap[status] === undefined) {
        return status
      }
      return this.insurerMap[status]
    },
    humanizeInsurers(insurers) {
      if (insurers === null) {
        return []
      }
      console.log(insurers)
      if (insurers.length < 1) {
        return []
      }
      for (const insurersKey in insurers) {
        if (this.insurerMap[insurers[insurersKey]] === undefined) {
          continue
        }
        insurers[insurersKey] = this.insurerMap[insurers[insurersKey]]
      }
      return insurers
    },
    humanizeProcessFail(process) {
      if (this.errorMap[process.errorCode] === undefined) {
        return process.errorCode + ": " + process.error
      }
      return process.errorCode + ": " + this.errorMap[process.errorCode]
    },
    humanizeOfferFail(process) {
      if (this.errorMap[process.errorCode] === undefined) {
        return process.errorCode
      }
      return process.errorCode + ": " + this.errorMap[process.errorCode]
    },
    humanizeReject(process) {
      let v = process.rejectType
      for (const rejectTypeOptionsKey in this.rejectTypeOptions) {
        if (this.rejectTypeOptions[rejectTypeOptionsKey].value === process.rejectType) {
          v = this.rejectTypeOptions[rejectTypeOptionsKey].label.toLowerCase()
        }
      }
      if (process.rejectType === 'over') {
        v += ': "' + process.rejectComment + '"'
      }
      return v
    },
    loadProcess() {
      this.loading = true
      axiosApiInstance.get(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/processes/" + this.$route.params.id)
          .then((response) => {
            let compare = function (a, b) {
              if (b.premium === "") {
                return 1
              }
              if (a.premium < b.premium) {
                return -1;
              }
              if (a.premium > b.premium) {
                return 1;
              }
              return 0;
            }
            response.data.offers.sort(compare);

            console.log("offers:", response.data.offers)
            let reqCheck = response.data.offers.length < 1
            for (const offersKey in response.data.offers) {
              if (response.data.offers[offersKey].policy !== undefined) {
                this.policy = response.data.offers[offersKey].policy
                this.policy.isActivable = this.policy.status === "created"
                if (["new", "creating", "activating"].includes(this.policy.status)) {
                  reqCheck = true
                }
              }
              if (response.data.offers[offersKey].status === 'processing') {
                reqCheck = true
              }
            }
            if (response.data.status === 'completed' || response.data.status === 'failed') {
              reqCheck = false
            }
            this.reqCheck = reqCheck
            this.process = response.data

            let data = JSON.parse(this.process.data)
            this.dataOsago = data.osagoData
            this.contId = data.contId
            this.loading = false
          }).catch(error => {
        this.data.loading = false
        console.log("view error ", error)
      });
    },
    offerDisabled(offer) {
      if (offer.premium < 1 || offer.status !== 'completed') {
        return true
      }
      let exp = moment(offer.expiredAt).utc(true).startOf('day')
      let now = moment().utc(true).startOf('day')
      console.log(now.isAfter(exp), now.valueOf(), exp.valueOf())
      return now.isAfter(exp);
    },
    offerLastDate(offer) {
      if (offer.expireAt < 1) {
        return ""
      }
      return moment(offer.expiredAt).utc(true).format("YYYY-MM-DD")
    },
    createPolicyByOffer(offer) {
      if (offer.premium < 1 || offer.status !== 'completed') {
        return
      }
      if (!confirm("Оформить полис?")) {
        return
      }
      if (this.offerDisabled(offer)) {
        alert("Предложение устарело")
        return
      }
      this.loading = true
      store.commit('createPolicy', {
        offerId: offer.id,
        onCreate: (data) => {
          router.push({name: 'PolicyView', params: {id: data.id}})
          this.loading = false
        },
        onError: () => {
          alert("Ошибка оформления")
          this.loading = false
        }
      })
    },
    rejectProcess() {
      if (!confirm("Клиент отказался от предложений?")) {
        return
      }

      store.commit('rejectProcess', {
        id: this.process.id,
        type: this.process.rejectType,
        comment: this.process.rejectComment,
        onSuccess: () => {
          this.$router.go()
        },
        onFail: () => {
          alert("Ошибка отказа от предложений")
        }
      })
    },
    navigateToPolicy(policy) {
      this.$router.push({name: 'PolicyView', params: {id: policy.id}})
    },
    recalc(process) {
      router.push({name: 'ProcessCreate', params: {sourceId: process.id}})
    },
    isStopped(process) {
      if (process.run && process.status==='ready_to_run') {
        return false;
      }
      return process.status!=='wait' && process.status!=='running';
    },
    formatDate(time) {
      if (time === undefined) {
        return ""
      }
      return moment(time).utc(true).format("YYYY-MM-DD")
    },
    formatDateTime(time) {
      if (time === undefined) {
        return ""
      }
      return moment(time).utc(true).format("YYYY-MM-DD HH:mm:ss")
    },
    downloadFile(policy, type) {
      if (!policy.haveCalculationFile) {
        return
      }
      axiosApiInstance.get(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/offers/" + policy.id + "/files/" + type)
          .then((response) => {
            console.log("file url ", response.data.url)
            window.location.href = response.data.url
          })
    },
  },
}
</script>

<style scoped>

.data {
  text-align: left;
}

</style>
