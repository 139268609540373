<template>
  <div>

    <el-form :inline="true" class="form-inline">
      <el-button size="small" type="warning" plain @click="createCard">Создать карту</el-button>
      <br>
      <br>
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input v-model="filter.id" size="small" placeholder="Id"></el-input>
        </el-col>
        <el-col :span="4" :xs="5">
          <el-input v-model="filter.carIdentity" size="small" placeholder="Авто"></el-input>
        </el-col>
        <el-col :span="4" :xs="5">
          <el-input v-model="filter.contragentId" size="small" placeholder="Конт"></el-input>
        </el-col>


        <el-col :span="2" :xs="4">
          <el-button type="primary" size="small" plain @click="filterList">Поиск</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-table :data="cards" style="width: 100%">
      <el-table-column>
        <template #default="scope">
          <el-button size="small" plain
                     @click="navigateCard(cards[scope.$index])">Просмотр
          </el-button>
        </template>
      </el-table-column>


      <el-table-column prop="id" label="Номер карты" width="100"></el-table-column>
      <el-table-column prop="contragentId" label="Контрагент" width="300">
        <template #default="scope">
          <div >{{ scope.row.contragentId }}</div>
          <div >{{ scope.row.orgName }}</div>
          <div >{{ scope.row.orgInn }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="govNumber" label="Авто">
        <template #default="scope">
          <div v-if="scope.row.govNumber!==null">{{ scope.row.govNumber }}</div>
          <div v-if="scope.row.vin!==null">{{ scope.row.vin }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="orgInn" label="ИНН"/>
      <el-table-column prop="kaskoFuelActive" label="Полис до">
        <template #default="scope">
          <div v-if="scope.row.coveredUntil!==null">{{ formatCoveredTime(scope.row.coveredUntil) }}</div>
        </template>
      </el-table-column>
      <el-table-column>
        <template #default="scope">
          <el-button v-if="cards[scope.$index].kaskoFuelActive === true" size="small" plain
                     @click="changeStatus(cards[scope.$index], false)">Деактивировать
          </el-button>
          <el-button v-if="cards[scope.$index].kaskoFuelActive === false" size="small" plain
                     @click="changeStatus(cards[scope.$index], true)">Активировать
          </el-button>
        </template>
      </el-table-column>
      <el-table-column>
        <template #default="scope">
          <el-button size="small" type="danger" plain
                     @click="del(cards[scope.$index])">Удалить
          </el-button>
        </template>
      </el-table-column>

    </el-table>
    <br/>
    <el-pagination
        :page-size="meta.pageSize"
        :pager-count="11"
        layout="prev, pager, next"
        :total="meta.pageCount*meta.pageSize"
        @current-change="setPage"
    >
    </el-pagination>
  </div>
</template>

<script>


import store from "../../store";
import moment from "moment";
import router from "@/router";

export default {
  name: 'CardList',
  data: function () {
    return {
      filter: {page: 1, id: "", govNumber: "", vin: "", contragentId: "", carIdentity: ""},
    }
  },
  computed: {
    cards: function () {
      return this.$store.getters.cards.data
    },
    meta: function () {
      return this.$store.getters.cards.meta
    }
  },
  created: function () {
    this.filter = store.getters.cards.filter
    this.$store.commit('updateCards', this.filter)
  },
  methods: {
    setPage(val) {
      this.filter.page = val
      this.filterList()
    },
    formatCoveredTime(val) {
      if (val === undefined || val === null) {
        return ""
      }
      return moment(val).utc().format("YYYY-MM-DD HH:mm")
    },
    filterList() {
      if (this.filter.carIdentity.length > 10) {
        this.filter.vin = this.filter.carIdentity.trim()
      } else {
        this.filter.vin = ""
        this.filter.govNumber = this.filter.carIdentity.trim()
      }
      this.filter.contragentId = this.filter.contragentId.trim()
      this.$store.commit('updateCards', this.filter)
    },
    navigateCard(card) {
      this.$router.push({name: 'CardView', params: {id: card.id}})
    },
    createCard() {
      router.push({name: 'CardCreate'})
    },
    statusFormatter(row, col, val) {
      return val
    },
    formatDateTime(row, col, val) {
      if (val === undefined) {
        return ""
      }
      return moment(val).utc(true).format("YYYY-MM-DD")
    },

    del(card) {
      if (confirm("Удалить карту " + card.id + "?")) {
        store.commit('deleteCard', {
          id: card.id,
          onDelete: () => {
            this.filterList(this.filter)
          },
          onError: () => {
            alert("Ошибка удаления")
            this.filterList(this.filter)
          }
        })
      }
    },
    changeStatus(card, active) {
      store.commit('changeCardStatus', {
        id: card.id,
        active: active,
        onChangeStatus: () => {
          this.filterList(this.filter)
        },
        onError: () => {
          alert("Ошибка активации/деактивации")
          this.filterList(this.filter)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

</style>
