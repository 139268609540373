<template>
  <div v-loading="loading">
    <h1>Запрос офферов</h1>

    <el-row :gutter="10">
      <el-col>
        <el-card>
          <el-tabs type="border-card">
            <el-tab-pane label="Страхователь">
              <div id="holder">
                <el-form ref="holderForm" :rules="orgRules" label-position="right" label-width="250px"
                         :model="form.holder">
                  <el-form-item>
                    <el-button @click="copyOwnerToHolder">Скопировать данные из владельца</el-button>
                    <el-button @click="swapHolderWithOwner">Поменять местами данные с владельцем</el-button>
                  </el-form-item>
                  <el-form-item label="Поиск по ИНН">
                    <el-autocomplete
                        @select="innSelect"
                        v-model="holderSearch"
                        ref="holderSearch"
                        placeholder="Введите номер ИНН"
                        :fetch-suggestions="getHolderOptions"
                    >
                      <template #append>
                        <el-button type="primary"
                                   @click="searchByInn( 'holder')">Поиск
                        </el-button>
                      </template>
                    </el-autocomplete>
                  </el-form-item>
                  <el-form-item label="ИНН" prop="inn">
                    <el-input placeholder="590201001" v-model="form.holder.inn"></el-input>
                  </el-form-item>
                  <el-form-item label="КПП" prop="kpp">
                    <el-input placeholder="590201001" v-model="form.holder.kpp"></el-input>
                  </el-form-item>
                  <el-form-item label="ОГРН" prop="orgn">
                    <el-input placeholder="1110107000629" v-model="form.holder.ogrn"></el-input>
                  </el-form-item>
                  <el-form-item label="Дата ОГРН" prop="orgnDate">
                    <el-input placeholder="1110107000629" v-model="form.holder.ogrnDate"></el-input>
                  </el-form-item>
                  <el-form-item label="Email" prop="email">
                    <el-input placeholder="Адрес почты" v-model="form.holder.email"></el-input>
                  </el-form-item>
                  <el-form-item label="Телефон" prop="phone">
                    <el-input placeholder="Номер телефона" v-model="form.holder.phone"></el-input>
                  </el-form-item>
                  <el-form-item label="Наименование компании" prop="name">
                    <el-input placeholder="ООО САТУРН-Р" v-model="form.holder.name"></el-input>
                  </el-form-item>
                  <el-form-item label="Серия документа" prop="docSeries">
                    <el-input placeholder="77" v-model="form.holder.docSeries"></el-input>
                  </el-form-item>
                  <el-form-item label="Номер документа" prop="docNumber">
                    <el-input placeholder="111111111" v-model="form.holder.docNumber"></el-input>
                  </el-form-item>
                  <el-form-item label="Код ОКОПФ" prop="opf">
                    <el-input placeholder="111111111" v-model="form.holder.opf"></el-input>
                  </el-form-item>
                </el-form>
                <el-form ref="holderAddrForm" :rules="orgRules" label-position="right" label-width="250px"
                         :model="form.holder.addressFact">
                  <el-form-item label="Юридический адрес" prop="full">
                    <el-autocomplete
                        style="width:100%"
                        v-model="form.holder.addressFact.full"
                        :fetch-suggestions="findAddress"
                        @select="holderAddressChange"
                        placeholder="Введите часть адреса"
                    />
                  </el-form-item>
                </el-form>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Владелец">
              <div id="owner">
                <el-form ref="ownerForm" :rules="orgRules" label-position="right" label-width="250px"
                         :model="form.owner">
                  <el-form-item>
                    <el-button @click="copyHolderToOwner">Скопировать данные из страхователя</el-button>
                    <el-button @click="swapHolderWithOwner">Поменять местами данные со страхователем</el-button>
                  </el-form-item>
                  <el-form-item label="Поиск по ИНН">
                    <el-autocomplete
                        @select="innSelect"
                        placeholder="Введите номер ИНН"
                        v-model="ownerSearch"
                        ref="ownerSearch"
                        :fetch-suggestions="getOwnerOptions"
                    >
                      <template #append>
                        <el-button type="primary" @click="searchByInn( 'owner')">
                          Поиск
                        </el-button>
                      </template>
                    </el-autocomplete>
                  </el-form-item>
                  <el-form-item label="ИНН" prop="inn">
                    <el-input placeholder="590201001" v-model="form.owner.inn"></el-input>
                  </el-form-item>
                  <el-form-item label="КПП" prop="kpp">
                    <el-input placeholder="590201001" v-model="form.owner.kpp"></el-input>
                  </el-form-item>
                  <el-form-item label="ОГРН" prop="orgn">
                    <el-input placeholder="1110107000629" v-model="form.owner.ogrn"></el-input>
                  </el-form-item>
                  <el-form-item label="Дата ОГРН" prop="orgnDate">
                    <el-input placeholder="1110107000629" v-model="form.owner.ogrnDate"></el-input>
                  </el-form-item>
                  <el-form-item label="Email" prop="email">
                    <el-input placeholder="Адрес почты" v-model="form.owner.email"></el-input>
                  </el-form-item>
                  <el-form-item label="Телефон" prop="phone">
                    <el-input placeholder="Номер телефона" v-model="form.owner.phone"></el-input>
                  </el-form-item>
                  <el-form-item label="Наименование компании" prop="name">
                    <el-input placeholder="ООО САТУРН-Р" v-model="form.owner.name"></el-input>
                  </el-form-item>
                  <el-form-item label="Серия документа" prop="docSeries">
                    <el-input placeholder="77" v-model="form.owner.docSeries"></el-input>
                  </el-form-item>
                  <el-form-item label="Номер документа" prop="docNumber">
                    <el-input placeholder="111111111" v-model="form.owner.docNumber"></el-input>
                  </el-form-item>
                  <el-form-item label="Код ОКОПФ" prop="opf">
                    <el-input placeholder="1050" v-model="form.owner.opf"></el-input>
                  </el-form-item>
                </el-form>
                <el-form ref="ownerAddrForm" :rules="orgRules" label-position="right" label-width="250px"
                         :model="form.owner.addressFact">
                  <el-form-item label="Юридический адрес" prop="full">
                    <el-autocomplete
                        style="width:100%"
                        v-model="form.owner.addressFact.full"
                        :fetch-suggestions="findAddress"
                        @select="ownerAddressChange"
                        :debounce="1000"

                        placeholder="Введите часть адреса"
                    />
                  </el-form-item>
                </el-form>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Машина">
              <div v-for="car in form.cars" :key="car.key">
                <el-form ref="carForm" :rules="carRules" label-position="right" label-width="250px"
                         :model="car">
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <el-form-item label="Дата начала полиса" prop="dateStartStr">
                        <el-date-picker :disabled="car.sync" placeholder="2022-01-01" size="small"
                                        v-model="car.dateStartStr"></el-date-picker>
                      </el-form-item>
                      <el-form-item label="VIN" prop="vin">
                        <el-input :disabled="car.sync" placeholder="XTA212140D2125175" v-model="car.vin"></el-input>
                      </el-form-item>
                      <el-form-item label="Номер кузова" prop="bodyNumber">
                        <el-input :disabled="car.sync" placeholder="1234556789" v-model="car.bodyNumber"></el-input>
                      </el-form-item>
                      <el-form-item label="Номер шасси" prop="chassisNumber">
                        <el-input :disabled="car.sync" placeholder="1234556789" v-model="car.chassisNumber"></el-input>
                      </el-form-item>
                      <el-form-item label="Госномер транспортного средства" prop="govNumber">
                        <el-input :disabled="car.sync" placeholder="К925УЕ22" v-model="car.govNumber">
                          <template #append>
                            <el-button type="primary" @click="searchByGovNumber(car,false)">Поиск</el-button>
                          </template>
                        </el-input>
                        <el-progress v-if="car.syncPercent>0" :percentage="car.syncPercent" :status="car.syncResult"/>
                      </el-form-item>
                      <el-form-item label="Производитель" prop="maker">
                        <el-input :disabled="car.sync" placeholder="Lada" v-model="car.maker"></el-input>
                      </el-form-item>
                      <el-form-item label="Модель" prop="model">
                        <el-input :disabled="car.sync" placeholder="212140" v-model="car.model"></el-input>
                      </el-form-item>
                      <el-form-item label="Категория транспортного средства" prop="category">
                        <el-select-v2
                            :disabled="car.sync"
                            v-model="car.category"
                            :options="carCategories"
                            placeholder="Выбрать тип"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Цель использования" prop="targetOfUsage">
                        <el-select-v2
                            :disabled="car.sync"
                            v-model="car.targetOfUsage"
                            :options="targetOfUsage"
                            placeholder="Выбрать тип"
                        />
                      </el-form-item>
                      <el-form-item label="Год производства" prop="year">
                        <el-input :disabled="car.sync" placeholder="2013" maxlength="4" v-model="car.year"></el-input>
                      </el-form-item>
                      <el-form-item label="Мощность транспортного средства" prop="year">
                        <el-input :disabled="car.sync" placeholder="82.9" v-model="car.power"></el-input>
                      </el-form-item>
                      <el-form-item v-if="car.category==='C'" label="Разрешенная макс. масса" prop="maxWeight">
                        <el-input :disabled="car.sync" placeholder="1500" v-model="car.maxWeight">
                          <template #append>КГ</template>
                        </el-input>
                      </el-form-item>
                      <el-form-item v-if="car.category==='D'" label="Кол-во пасс. мест" prop="maxPassengers">
                        <el-input :disabled="car.sync" placeholder="20" v-model="car.maxPassengers">
                        </el-input>
                      </el-form-item>
                      <el-form-item label="ТС используется с прицепом" prop="haveTrailer">
                        <el-radio v-model="car.haveTrailer" :label="true">Да</el-radio>
                        <el-radio v-model="car.haveTrailer" :label="false">Нет</el-radio>
                      </el-form-item>
                      <el-form-item label="Регион использования" prop="region">
                        <el-autocomplete
                            style="width:100%"
                            v-model="car.regionOfUse"
                            :fetch-suggestions="findRegion"
                            @select="regionChange"
                            :debounce="1000"
                            placeholder="Регион"
                        />
                      </el-form-item>
                      <el-form-item label="Кладр региона использования">
                        <el-input disabled
                                  style="width:100%"
                                  v-model="car.regionOfUseKladr"
                        />
                      </el-form-item>
                      <el-form-item label="Город использования" prop="region">
                        <el-autocomplete
                            style="width:100%"
                            v-model="car.cityOfUse"
                            :fetch-suggestions="findCity"
                            @select="cityChange"
                            :debounce="1000"
                            placeholder="Регион"
                        />
                      </el-form-item>
                      <el-form-item label="Кладр города использования">
                        <el-input disabled
                                  style="width:100%"
                                  v-model="car.cityKladr"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
                <div>


                  <el-form ref="carDocForm" :rules="carDocRules" label-position="right" label-width="250px"
                           :model="document">
                    <el-tabs type="border-card">
                      <el-tab-pane label="ПТС">
                        <div>Паспорт ТС</div>
                        <el-form-item label="Номер" prop="number">
                          <el-input placeholder="518511" v-model="car.ptsNumber"></el-input>
                        </el-form-item>
                        <el-form-item label="Серия" prop="series">
                          <el-input placeholder="63НС" v-model="car.ptsSeries"></el-input>
                        </el-form-item>
                        <el-form-item label="Дата" prop="dateStr">
                          <el-date-picker placeholder="2023-01-01" size="small"
                                          v-model="car.ptsDateStr"></el-date-picker>
                        </el-form-item>
                      </el-tab-pane>
                      <el-tab-pane label="СТС">
                        <div>Свидетельство о регистрации ТС</div>
                        <el-form-item label="Номер" prop="number">
                          <el-input placeholder="518512" v-model="car.stsNumber"></el-input>
                        </el-form-item>
                        <el-form-item label="Серия" prop="series">
                          <el-input placeholder="63НС" v-model="car.stsSeries"></el-input>
                        </el-form-item>
                        <el-form-item label="Дата" prop="dateStr">
                          <el-date-picker placeholder="2023-01-01" size="small"
                                          v-model="car.stsDateStr"></el-date-picker>
                        </el-form-item>
                      </el-tab-pane>
                      <el-tab-pane label="ЕПТС">
                        <div>Электронный паспорт ТС</div>
                        <el-form-item label="Номер" prop="number">
                          <el-input placeholder="518513" v-model="car.eptsNumber"></el-input>
                        </el-form-item>
                        <el-form-item label="Серия" prop="series">
                          <el-input placeholder="63НС" v-model="car.eptsSeries"></el-input>
                        </el-form-item>
                        <el-form-item label="Дата" prop="dateStr">
                          <el-date-picker placeholder="2023-01-01" size="small"
                                          v-model="car.eptsDateStr"></el-date-picker>
                        </el-form-item>
                      </el-tab-pane>
                      <el-tab-pane label="ПСМ">
                        <div>Паспорт самоходной машины</div>
                        <el-form-item label="Номер " prop="number">
                          <el-input placeholder="518514" v-model="car.psmNumber"></el-input>
                        </el-form-item>
                        <el-form-item label="Серия" prop="series">
                          <el-input placeholder="63НС" v-model="car.psmSeries"></el-input>
                        </el-form-item>
                        <el-form-item label="Дата" prop="dateStr">
                          <el-date-picker placeholder="2023-01-01" size="small"
                                          v-model="car.psmDateStr"></el-date-picker>
                        </el-form-item>
                      </el-tab-pane>
                      <el-tab-pane label="Другой документ">
                        <div>Аналогичные документы на ТС</div>
                        <el-form-item label="Номер" prop="number">
                          <el-input placeholder="518515" v-model="car.overDocNumber"></el-input>
                        </el-form-item>
                        <el-form-item label="Серия" prop="series">
                          <el-input placeholder="63НС" v-model="car.overDocSeries"></el-input>
                        </el-form-item>
                        <el-form-item label="Дата" prop="dateStr">
                          <el-date-picker placeholder="2023-01-01" size="small"
                                          v-model="car.overDocDateStr"></el-date-picker>
                        </el-form-item>
                      </el-tab-pane>
                    </el-tabs>

                  </el-form>
                </div>
              </div>
            </el-tab-pane>

          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
    <br/>
    <el-row>
      <el-col>
        <el-card>
          <el-form :model="form.insurers">
            <el-form-item label="Страховые" prop="insurers">
              <el-select
                  v-model="form.insurers"
                  multiple
                  collapse-tags
                  placeholder="Все возможные страховые"
                  style="width: 240px"
              >
                <el-option
                    v-for="item in insurers"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <br/>
    <el-alert v-if="errorMessage" style="text-align: left" show-icon type="error" title="Ошибка запроса офферов"
              :description="errorMessage"></el-alert>
    <br/>
    <el-button @click="clearOfferRequest">Очистить</el-button>
    <el-button type="primary" @click="submitForm()" :disabled="this.loading">Запросить офферы</el-button>
  </div>
</template>

<script>

import store from "../store";
import router from '../router/index.js'
import moment from "moment";
import axiosApiInstance from "../axios";

export default {
  name: 'ProcessEdit',
  data: function () {
    let requiredValidator = {required: true, message: "это поле обязательно"}
    let form = {
      insurers: ["renaissance", "alfa"],
      kpp: "",
      holder: {
        inn: "",
        kpp: "",
        ogrn: "",
        opf: "",
        ogrnDate: "",
        email: "strahovka@pprcard.ru",
        phone: "+79001234567",
        name: "",
        docNumber: "",
        docSeries: "",
        addressFact: {
          country: "Россия",
          city: "",
          street: "",
          house: "",
          full: "",
          kladrId: "",
          cityKladr: "",
          region: "",
          regionKladrId: ""
        }
      },
      owner: {
        inn: "",
        innLoding: "",
        ogrn: "",
        ogrnDate: "",
        kpp: "",
        opf: "",
        email: "strahovka@pprcard.ru",
        phone: "+79001234567",
        name: "",
        docNumber: "",
        docSeries: "",
        addressFact: {
          country: "Россия",
          city: "",
          street: "",
          house: "",
          full: "",
          kladrId: "",
          cityKladr: "",
          region: "",
          regionKladrId: ""
        }
      },
      cars: [
        {
          vin: "",
          bodyNumber: "",
          chassisNumber: "",
          key: 0,
          id: "",
          idType: "",
          maker: "",
          model: "",
          category: "",
          year: "",
          power: "",
          govNumber: "",
          targetOfUsage: "over",
          maxPassengers: "",
          maxWeight: "",
          haveTrailer: false,
          cityOfUse: "",
          regionOfUse: "",
          regionOfUseKladr: "",
        }
      ]
    }
    return {
      insurers: [
        {"label": "Альфастрахование", "value": "alfa"},
        {"label": "Ренессанс", "value": "renaissance"},
        // {"label": "Ресо", "value": "elt_reso"},
        // {"label": "Ресо", "value": "elt_renins"},
        {"label": "ВСК", "value": "elt_vsk"},
      ],
      loading: false,
      errorMessage: "",
      numberValidateForm: {
        age: '',
      },
      ownerOptions: [],
      holderOptions: [],
      holderSearch: "",
      ownerSearch: "",
      form: form,
      emptyFor: JSON.parse(JSON.stringify(form)),
      targetOfUsage: [
        {value: "over", label: "Прочие"},
        {value: "personal", label: "Личные"},
        {value: "taxi", label: "Такси"},
        {value: "learning", label: "Учебная езда"},
        {value: "special", label: "Дорожные и специальные ТС"},
        {value: "passenger", label: "Регулярные/по заказам пассажирские перевозки"},
        {value: "danger", label: "Перевозка опасных и легко воспламеняющихся грузов"},
        {value: "rental", label: "Прокат/Краткосрочная аренда"},
        {value: "utility", label: "Экстренные и коммунальные службы"},
      ],
      carCategories: [
        {value: "A", label: "A"},
        {value: "B", label: "B"},
        {value: "C", label: "C"},
        {value: "D", label: "D"},
        {value: "E", label: "E"},
        {value: "Tm", label: "Tm"},
        {value: "Tb", label: "Tb"},
      ],
      orgRules: {
        inn: [
          requiredValidator,
          {required: true, pattern: /^[\d]{10,12}$/, trigger: "blur", "message": "необходимо указать 10-12 цифр"}
        ],
        phone: [
          {required: true, pattern: /^[\d]{4,11}$/, trigger: "blur", "message": "необходимо указать 4-11 цифр"}
        ],
        kpp: [
          requiredValidator,
          {pattern: /^\d{9}$/, trigger: "blur", "message": "допустимы 9 цифр"}
        ],
        ogrn: [
          requiredValidator,
          {pattern: /^\d{13}$/, trigger: "blur", "message": "допустимы 9 цифр"}
        ],
        ogrnDate: [
          requiredValidator,
          {pattern: /^\d{4}-\d{2}-\d{2}$/, trigger: "blur", "message": "формат даты: 2020-01-01"}
        ],
        email: [
          requiredValidator,
          {
            pattern: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
            trigger: "blur",
            "message": "требуется указать email"
          }
        ],
        name: [
          requiredValidator,
          {pattern: /^[а-яА-Яa-zA-ZёЁЙ0-9" -]+$/, trigger: "blur", "message": "поле содержит недопустимые символы"}
        ],
        docSeries: [
          {pattern: /^\d{2}$/, trigger: "blur", "message": "поле должно содержать две цифры"}
        ],
        docNumber: [
          {pattern: /^[0-9]+$/, trigger: "blur", "message": "поле должно содержать цифры"}
        ],
        opf: [
          {pattern: /^[0-9]+$/, trigger: "blur", "message": "поле должно содержать цифры"}
        ],
        full: [
          requiredValidator,
          {pattern: /^[а-яйА-ЯЙЁVIё0-9\s,./-]+$/, trigger: "blur", "message": "поле содержит недопустимые символы"}
        ],
      },
      carRules: {
        dateStartStr: [
          requiredValidator,
          //  {pattern: /^\d{4}-\d{2}-\d{2}$/, trigger: "blur", "message": "Требуемый формат 2020-01-01"}
        ],
        id: [
          requiredValidator,
          {pattern: /^[a-zA-Z0-9]+$/, trigger: "blur", "message": "допустимы символы a-zA-Z0-9"}
        ],
        idType: [
          requiredValidator,
          {pattern: /^[a-z]+$/, trigger: "blur"}
        ],
        maker: [
          requiredValidator,
          {pattern: /^[a-zA-Zа-яА-Я0-9 ]+$/, trigger: "blur", "message": "допустимы символы a-z"}
        ],
        model: [
          requiredValidator,
          {pattern: /^[a-zA-Z0-9А-Яа-я0-9 -]+$/, trigger: "blur", "message": "допустимы символы a-zA-Z0-9"}
        ],
        category: [
          requiredValidator,
          {pattern: /^[a-zA-Z]{1,2}$/, trigger: "blur"}
        ],
        year: [
          requiredValidator,
          {pattern: /^\d{4}$/, trigger: "blur"}
        ],
        power: [
          requiredValidator,
          {pattern: /^\d+(\.\d)*$/, trigger: "blur"}
        ],
        govNumber: [
          requiredValidator,
          {pattern: /^[\dа-яa-zA-ZА-Я ]+\d{1,3}$/, trigger: "blur"}
        ],
        targetOfUsage: [
          requiredValidator,
          {pattern: /^[a-z]+$/, trigger: "blur"}
        ],
        maxPassengers: [
          {pattern: /^\d+$/, trigger: "blur"}
        ],
        maxWeight: [
          {pattern: /^\d+$/, trigger: "blur"}
        ],
        haveTrailer: [
          requiredValidator,
        ],
      },
      carDocRules: {
        type: [
          {pattern: /^[a-z]+$/, trigger: "blur"}
        ],
        number: [
          {pattern: /^[0-9]+$/, trigger: "blur"}
        ],
        series: [
          {pattern: /^[0-9А-Я]{4}$/, trigger: "blur"}
        ],
        dateStr: [
          {pattern: /^\d{4}-\d{2}-\d{2}$/, trigger: "blur", "message": "Требуемый формат 2020-01-01"}
        ],
      },
    }
  },
  methods: {
    getOwnerOptions(queryString, cb) {
      console.log("getOwnerOptions", this.ownerOptions)
      return cb(this.ownerOptions)
    },
    getHolderOptions(queryString, cb) {
      return cb(this.holderOptions)
    },
    innSelect(line) {
      console.log("line found", line)
      let org = line.org
      this.form[line.target].addressFact.full = org.addressFull
      this.form[line.target].addressFact.city = org.address.city
      this.form[line.target].addressFact.cityKladr = org.address.cityKladrId
      this.form[line.target].addressFact.street = org.address.streetWithType
      this.form[line.target].addressFact.house = org.address.houseType + " " + org.address.house
      this.form[line.target].addressFact.kladrId = org.address.kladrId
      this.form[line.target].addressFact.region = org.address.region
      this.form[line.target].addressFact.regionKladrId = org.address.regionKladrId
      this.form[line.target].addressJuridical = this.form[line.target].addressFact
      this.form[line.target].kpp = org.kpp
      this.form[line.target].inn = org.inn
      this.form[line.target].ogrn = org.ogrn
      this.form[line.target].ogrnDate = org.ogrnDate
      let name = org.opfShort.trim() + " " + org.nameShort.trim()
      name = name.replaceAll(/[\t\s]+/g, " ").replaceAll(/[^а-яА-Яa-zA-Z0-9"\s-]+/g, "")
      this.form[line.target].name = name
      this.form[line.target].opf = org.opfCode
    },
    ownerAddressChange(d) {
      let house = d.data.data.house
      if (d.data.data.block_type !== null) {
        house += " " + d.data.data.block_type + " " + d.data.data.block
      }
      this.form.owner.addressFact.city = d.data.data.city
      this.form.owner.addressFact.cityKladr = d.data.data.city_kladr_id
      this.form.owner.addressFact.street = d.data.data.street_with_type
      this.form.owner.addressFact.streetKladrId = d.data.data.street_kladr_od
      this.form.owner.addressFact.house = house
      this.form.owner.addressFact.region = d.data.data.region
      this.form.owner.addressFact.regionKladrId = d.data.data.region_kladr_id
      this.form.owner.addressJuridical = this.form.owner.addressFact
    },
    regionChange(d) {
      this.form.cars[0].regionOfUse = d.data.region
      this.form.cars[0].regionOfUseKladr = d.data.region_kladr_id
    },
    cityChange(d) {
      this.form.cars[0].cityOfUse = d.data.city
      this.form.cars[0].cityKladr = d.data.city_kladr_id
    },
    holderAddressChange(d) {
      let house = d.data.data.house
      if (d.data.data.block_type !== null) {
        house += " " + d.data.data.block_type + " " + d.data.data.block
      }
      this.form.holder.addressFact.city = d.data.data.city
      this.form.holder.addressFact.cityKladr = d.data.data.city_kladr_id
      this.form.holder.addressFact.street = d.data.data.streetWithType
      this.form.holder.addressFact.streetKladrId = d.data.data.street_kladr_id
      this.form.holder.addressFact.house = house
      this.form.holder.addressFact.region = d.data.data.region
      this.form.holder.addressFact.regionKladrId = d.data.data.region_kladr_id
      this.form.holder.addressJuridical = this.form.holder.addressFact
    },
    submitForm() {
      this.loading = true
      let forms = ["carForm", "carDocForm", "ownerForm", "holderForm", "ownerAddrForm", "holderAddrForm"]
      let ok = true
      if (this.form.insurers !== null && this.form.insurers.length < 1) {
        alert("Необходимо выбрать страховые")
        this.loading = false
        return
      }
      for (const key in forms) {
        if (this.$refs[forms[key]] === 'undefined') {
          console.log("form not found by ref:" + forms[key])
          ok = false
          continue
        }
        this.$refs[forms[key]].validate((valid) => {
          console.log(forms[key], valid)
          if (valid) {
            ok = ok && true
          } else {
            ok = ok && false
            return false
          }
        })
      }
      if (ok) {
        this.createOfferProces()
      } else {
        alert("Ошибка валидации")
        this.loading = false
      }
    },
    copyOwnerToHolder() {
      this.form.holder = JSON.parse(JSON.stringify(this.form.owner))
    },
    copyHolderToOwner() {
      this.form.owner = JSON.parse(JSON.stringify(this.form.holder))
    },
    swapHolderWithOwner() {
      let holder = JSON.parse(JSON.stringify(this.form.holder))
      this.form.holder = JSON.parse(JSON.stringify(this.form.owner))
      this.form.owner = holder
    },
    searchByInn(target) {
      store.commit('searchOrg', {
        data: {
          org: this.form[target],
          search: this[target + "Search"].trim()
        },
        onCreate: (success, response) => {
          let options = []
          if (success) {
            if (response.founded) {
              for (const organizationsKey in response.organizations) {
                let org = response.organizations[organizationsKey]
                options.push({
                  target: target,
                  org: org,
                  value: org.address.city + ": " + org.opfShort + " " + org.nameShort + ". КПП:" + org.kpp
                })
              }
              this[target + "Options"] = options
              console.log("found", target + "Options", this[target + "Options"])

              this.$refs[target + "Search"].focus()
            } else {
              alert('Организация не найдена')
            }
          } else {
            alert('Ошибка поиска')
          }
        }
      })

    },
    searchByGovNumber(car, cont) {
      if (!cont && car.sync === true) {
        if (confirm("Остановить поиск?")) {
          car.sync = false
          car.syncResult = ''
          car.syncPercent = 0
          return
        }
      }
      if (!cont) {
        car.sync = true
        car.syncResult = ''
        car.syncPercent = 1
      }
      store.commit('searchCar', {
        data: car.govNumber.toLowerCase().trim(),
        onCreate: (success, response) => {
          if (car.syncPercent < 100) {
            car.syncPercent += 10
          }
          if (response.syncState === 'wait' || response.syncState === 'processing') {
            setTimeout(() => {
              if (!car.sync) {
                return;
              }
              this.searchByGovNumber(car, true)
            }, 10000)
            return;
          }

          if (success) {
            car.sync = false
            car.syncPercent = 100
            if (response.syncState === 'not_found') {
              car.syncResult = 'warning'
            } else {
              car.syncResult = 'success'
            }
            car.vin = response.vin
            car.bodyNumber = response.bodyNumber
            car.chassisNumber = response.chassisNumber
            car.govNumber = response.grz.value
            car.maker = response.maker
            car.model = response.model
            car.category = response.category
            car.year = response.year
            car.power = response.power
            car.cityOfUse = response.cityOfUse
            car.regionOfUse = response.regionOfUse
            car.regionOfUseKladr = response.regionOfUseKladr
            car.id = response.id
            if (car.category === "C") {
              car.maxWeight = response.maxWeight
            }
            if (car.category === "D") {
              car.maxPassengers = response.maxPassengers
            }
            car.haveTrailer = response.haveTrailer
            car.syncState = response.syncState

            if (response.pts.date !== undefined) {
              car.ptsDate = response.pts.date
              car.ptsDateStr = moment(response.pts.date).utc(true).format("YYYY-MM-DD")
              car.ptsNumber = response.pts.number
              car.ptsSeries = response.pts.series
            } else if (response.sts.date !== undefined) {
              car.stsDate = response.sts.date
              car.stsDateStr = moment(response.sts.date).utc(true).format("YYYY-MM-DD")
              car.stsNumber = response.sts.number
              car.stsSeries = response.sts.series
            }

          } else {
            car.syncPercent = 50
            car.syncResult = 'exception'
            car.sync = false
            alert('Ошибка поиска')
          }
        }
      })

    },
    createOfferProces() {
      let data = this.form
      let fields = ["pts", "sts", "epts", "psm", "overDoc"]
      for (const carsKey in data.cars) {
        for (const fKey in fields) {
          if (data.cars[carsKey][fields[fKey] + "DateStr"] !== undefined && data.cars[carsKey][fields[fKey] + "DateStr"] !== "") {
            data.cars[carsKey][fields[fKey] + "Date"] = moment(data.cars[carsKey][fields[fKey] + "DateStr"]).utc(true).valueOf()
          }
        }
        if (data.cars[carsKey]["dateStartStr"] !== undefined && data.cars[carsKey]["dateStartStr"] !== "") {
          let lastDate = moment(data.cars[carsKey]["dateStartStr"]).utc(true).startOf('day').subtract(4, 'days')
          let now = moment().utc(true).startOf('day')
          console.log("time left: ", lastDate.valueOf(), now.valueOf())
          if (now.isAfter(lastDate.utc(true))) {
            this.loading = false
            alert("Нельзя запросить предложения - дата создания должна быть на 4 дня в будущем")
            return
          }

          let date = moment(data.cars[carsKey]["dateStartStr"]).utc(true)
          data.cars[carsKey]["dateStart"] = date.valueOf()
          data.cars[carsKey]["year"] = parseInt(data.cars[carsKey]["year"], 10)
          if (data.cars[carsKey]["maxPassengers"] === "") {
            delete data.cars[carsKey]["maxPassengers"]
          }
          if (data.cars[carsKey]["targetOfUsage"] === "") {
            delete data.cars[carsKey]["targetOfUsage"]
          }
          if (data.cars[carsKey]["maxWeight"] === "") {
            delete data.cars[carsKey]["maxWeight"]
          } else {
            data.cars[carsKey]["maxWeight"] = parseInt(data.cars[carsKey]["maxWeight"], 10)
          }
        }
      }
      if (data.owner.docNumber === "" || data.owner.docSeries === "") {
        data.owner.docNumber = "111111111"
        data.owner.docSeries = "77"
      }
      if (data.holder.docNumber === "" || data.holder.docSeries === "") {
        data.holder.docNumber = "111111111"
        data.holder.docSeries = "77"
      }
      data.holder.addressJuridical = data.holder.addressFact
      data.owner.addressJuridical = data.owner.addressFact

      store.commit('createProcess', {
        data: data,
        onCreate: (success, response) => {
          if (success) {
            if (response.data.message !== undefined && response.data.message !== "") {
              confirm("Проблема при детектировании допустимых страховых:\n\n" + response.data.message)
            }
            router.push({name: 'ProcessView', params: {id: response.data.requestId}})
          } else {
            console.log("createProcess error ", success, response)
            if (response.status === 422) {
              this.errorMessage = response.data.message
            } else {
              this.errorMessage = response.status + ": ошибка создания процесса\n проблема:" + response.data.message
            }
            this.loading = false
          }
        }
      })
    },
    clearOfferRequest() {
      this.form = this.emptyForm
    },
    findAddress(queryString, cb) {
      if (queryString.trim() === "") {
        return
      }
      const instance = axiosApiInstance.create({
        baseURL: 'https://suggestions.dadata.ru',
        timeout: 1000,
        headers: {
          'Authorization': "Token " + process.env.VUE_APP_DADATA_TOKEN,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      });
      instance.post('/suggestions/api/4_1/rs/suggest/address', {"query": queryString})
          .then((result) => {
            console.log(result.data.suggestions)
            let options = []
            result.data.suggestions.forEach((v) => {
              options.push({
                label: v,
                value: v.value,
                data: v
              })
            })
            cb(options)
          }).catch(error => console.log("error", error))
    },
    findRegion(queryString, cb) {
      if (queryString.trim() === "") {
        return
      }
      const instance = axiosApiInstance.create({
        baseURL: 'https://suggestions.dadata.ru',
        timeout: 1000,
        headers: {
          'Authorization': "Token " + process.env.VUE_APP_DADATA_TOKEN,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      });
      instance.post('/suggestions/api/4_1/rs/suggest/address', {
        "query": queryString, "from_bound": {
          "value": "region"
        }
      })
          .then((result) => {
            let options = {}
            result.data.suggestions.forEach((v) => {
              options[v.data.region] = {
                label: v.data.region_with_type,
                value: v.data.region,
                data: v.data
              }
            })
            cb(Object.values(options))
          }).catch(error => console.log("error", error))
    },
    findCity(queryString, cb) {
      if (queryString.trim() === "") {
        return
      }
      const instance = axiosApiInstance.create({
        baseURL: 'https://suggestions.dadata.ru',
        timeout: 1000,
        headers: {
          'Authorization': "Token " + process.env.VUE_APP_DADATA_TOKEN,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      });
      let filter = {
        "query": queryString, "from_bound": {
          "value": "city"
        }
      }
      if (this.form.cars[0].regionOfUseKladr) {
        filter['locations'] = {'region': this.form.cars[0].regionOfUse}
      }

      instance.post('/suggestions/api/4_1/rs/suggest/address', filter)
          .then((result) => {
            let options = {}
            result.data.suggestions.forEach((v) => {
              options[v.data.region + "," + v.data.city_with_type] = {
                label: v.data.city_with_type,
                value: v.data.city_with_type,
                data: v.data
              }
            })
            cb(Object.values(options))
          }).catch(error => console.log("error", error))
    },
  },
  mounted() {
    let id = this.$router.currentRoute.value.params.sourceId
    if (id === undefined) {
      this.loading = false
      return
    }
    store.commit('fetchProcess', {
      data: id,
      onSuccess: (data) => {
        let reqData = JSON.parse(data.data)

        this.form = reqData.osagoData
        for (const carKey in this.form.cars) {
          let car = this.form.cars[carKey]
          if (car === undefined) {
            continue
          }
          if (car.ptsDate !== undefined) {
            car.ptsDateStr = moment(car.ptsDate).utc(true).format("YYYY-MM-DD")
          } else if (car.stsDate !== undefined) {
            car.stsDateStr = moment(car.stsDate).utc(true).format("YYYY-MM-DD")
          }
          if (car.dateStart !== "") {
            car.dateStartStr = moment(car.dateStart).utc(true).format("YYYY-MM-DD")
          }
          car.haveTrailer = car.haveTrailer === undefined ? false : car.haveTrailer
        }
      }
    })

  }
}
</script>

<style scoped>

</style>
