<template>
  <div class="data">
    <div>
      <div v-if="card.id">
        <h3>Карта {{ card.id }}</h3><br/>
        <el-button style="width:150px" type="success" size="small" @click="edit(card)" class="button">
          Редактировать
        </el-button>
        <br>
        <br>
        <el-row>
          <el-col :span="10">
            <div>ID контрагента (наш): {{ card.contragentId }}</div>
            <div>Почта: {{ card.mail }}</div>
            <div>ИНН собственника авто: {{ card.orgInn }}</div>
            <div>Организация: {{ card.orgName }}</div>
            <div>Гос номер: {{ card.govNumber }}</div>
            <div>VIN: {{ card.vin }}</div>
            <div>Активна: {{ humanizeBoolean(card.kaskoFuelActive) }}</div>
            <div>Активно автопродление: {{ humanizeBoolean(card.autoCoverageActive) }}</div>
            <div>Текущее покрытие до: {{ formatDateTimeUtc(card.coveredUntil) }}</div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>

import router from "@/router";
import axiosApiInstance from "../../axios";
import moment from "moment";

export default {
  name: 'CardView',
  mounted() {
    this.loadCard()
  },
  data: function () {
    return {
      card: {},
      boolMap: {
        true: "Да",
        false: "Нет",
      },
    }
  },
  methods: {
    formatDateTime(time) {
      if (time === undefined || time === null) {
        return ""
      }
      return moment(time).utc(true).format("YYYY-MM-DD HH:mm:ss")
    },
    formatDateTimeUtc(time) {
      if (time === undefined || time === null) {
        return ""
      }
      return moment(time).utc().format("YYYY-MM-DD HH:mm:ss")
    },
    loadCard() {
      axiosApiInstance.get(process.env.VUE_APP_KASKO_SERVICE_URL + "/api/v1/fuel/cards/" + this.$route.params.id)
          .then((response) => {
            this.card = response.data.data
          }).catch(error => {
        console.log("view error ", error)
      });
    },
    navigateCard(card) {
      this.$router.push({name: 'CardView', params: {id: card.id}})
    },
    edit(card) {
      router.push({name: 'CardEdit', params: {id: card.id}})
    },
    humanizeBoolean(status) {
      return this.boolMap[status]
    },
  },
}
</script>

<style scoped>

.data {
  text-align: left;
}

</style>
