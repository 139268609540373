import {createRouter, createWebHistory} from 'vue-router'

import Login from "../components/Login";
import WizardView from "../components/Wizard";
import ProcessList from "../components/ProcessList";
import PolicyView from "../components/PolicyView";
import ProcessEdit from "../components/ProcessEdit";
import ProcessView from "../components/ProcessView";
import PolicyList from "../components/PolicyList";
import WaybillList from "../components/WaybillList";
import WaybillView from "../components/WaybillView";
import BatchList from "../components/BatchList";
import BatchView from "../components/BatchView";
import BatchCreate from "../components/BatchCreate";
import OsagoRegistryPoliciesList from "../components/osago/registry/PolicyList";
import OsagoRegistryPolicyView from "../components/osago/registry/PolicyView";
import OsagoRegistryPolicyEdit from "../components/osago/registry/PolicyEdit";
import OsagoCount from "../components/osago/count/OsagoCount";
import Kasko from "../components/kasko/CalculationsList.vue";
import CardList from "../components/kasko/CardList.vue";
import CardView from "../components/kasko/CardView.vue";
import CardEdit from "../components/kasko/CardEdit.vue";
import PolicyFileView from "../components/kasko/PolicyFileView.vue";

const routes = [
    {path: '/', component: Login, name: "Login"},
    {path: '/wizard', component: WizardView, name: "Wizard"},
    {path: '/cargo', component: WaybillList, name: "Waybills"},
    {path: '/cargo/:id', component: WaybillView, name: "WaybillView"},
    {path: '/osago/batches', component: BatchList, name: "Batches"},
    {path: '/osago/batches/create', component: BatchCreate, name: "BatchCreate"},
    {path: '/osago/batches/:id', component: BatchView, name: "BatchView"},
    {path: '/osago/processes', component: ProcessList, name: "Processes"},
    {path: '/osago/processes/:id', component: ProcessView, name: "ProcessView"},
    {path: '/osago/policies/:id', component: PolicyView, name: "PolicyView"},
    {path: '/osago/policies', component: PolicyList, name: "PoliciesList"},
    {path: '/osago/processes/create', component: ProcessEdit, name: "ProcessCreate"},
    {path: '/osago/registry/policies', component: OsagoRegistryPoliciesList, name: "OsagoRegistryPoliciesList"},
    {path: '/osago/registry/policies/edit', component: OsagoRegistryPolicyEdit, name: "OsagoRegistryPolicyCreate"},
    {path: '/osago/registry/policies/edit/:id', component: OsagoRegistryPolicyEdit, name: "OsagoRegistryPolicyEdit"},
    {path: '/osago/registry/policies/:id', component: OsagoRegistryPolicyView, name: "OsagoRegistryPolicyView"},
    {path: '/osago/count', component: OsagoCount, name: "OsagoCount"},
    {path: '/kasko/calculations', component: Kasko, name: "Kasko"},
    {path: '/kasko/cards', component: CardList, name: "CardList"},
    {path: '/kasko/cards/:id', component: CardView, name: "CardView"},
    {path: '/kasko/cards/edit', component: CardEdit, name: "CardCreate"},
    {path: '/kasko/cards/edit/:id', component: CardEdit, name: "CardEdit"},
    {path: '/kasko/policy/:id', component: PolicyFileView, name: "PolicyFileView"}
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;
