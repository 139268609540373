<template>
  <div>
    <el-table :data="osagoCountData" style="width: 100%">
      <el-table-column prop="carsCount" label="Всего машин"/>
      <el-table-column prop="osagoCount" label="Всего ОСАГО"/>
      <el-table-column prop="osagoCountEnding" label="Всего завершающихся ОСАГО" />
      <el-table-column prop="countOffersByEnding" label="Офферы по завершающимся"
       :formatter="(row, column, cv) => cv.reduce((str, item, index, arr) =>  index == arr.length - 1 ? str + `${item.insurerName}: ${item.offersCount} ` : str + `${item.insurerName}: ${item.offersCount}, `, '')"
      />
      <el-table-column prop="invoicesCountByWeek" label="Количествово запрошенных счетов за неделю" />
      <el-table-column prop="invoicesCountByMonth" label="Количествово запрошенных счетов за месяц" />
    </el-table>
    <br/>
  </div>
</template>

<script>

import store from "@/store";
export default {
  name: 'OsagoCount',
  computed: {
    osagoCountData: function () {
      return store.getters.osagoCount.data
    },
  },
  created: function () {
    this.filter = store.getters.osagoCount.filter
    store.commit('updateOsagoCount', this.filter)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.info-item {
  margin-bottom: 18px;
}
</style>
