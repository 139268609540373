<template>
  <div class="data">
    <div>
      <el-progress v-if="reqCheck" indeterminate="indeterminate" :percentage="reqCheckPercentage"
                   style="margin-bottom: 20px"
                   :show-text="false"/>
      <div v-if="batch.id">
        <h3>Пакетная заявка {{ batch.id }}</h3><br/>
        <el-row>
          <el-col :span="10">
            <div>Номер: {{ batch.id }}</div>
            <div>Статус: {{ humanizeStatus(batch.status) }}</div>
            <div>Создан: {{ formatDateTime(batch.createdAt) }}</div>
          </el-col>
        </el-row>
      </div>
      <br/>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>Процессы</span>
          </div>
        </template>
        <el-table :data="batch.counters">
          <el-table-column>
            <template #default="scope">
              <el-button size="small" plain @click="navigateToProcesses(batch.counters[scope.$index].status)">Просмотр
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="count" label="Количество"/>
          <el-table-column prop="status" :formatter="humanizeProcessStatus" label="Статус"/>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>

import router from "../router";
import moment from "moment";
import axiosApiInstance from "../axios";

export default {
  name: 'BatchView',
  mounted() {
    this.loadBatch()
    this.checkInterval = setInterval(() => {
      if (this.reqCheck) {
        this.reqCheckPercentage -= 10
        if (this.reqCheckPercentage === 0) {
          this.loadBatch()
          this.reqCheckPercentage = 100
        }
      }
    }, 500);
  },
  unmounted() {
    if (this.checkInterval) {
      this.reqCheckPercentage = 100
      clearInterval(this.checkInterval)
    }
  },
  data: function () {
    return {
      reqCheck: false,
      reqCheckPercentage: 100,
      loading: true,
      batch: {processes: []},
      checkInterval: null,
      statusMap: {
        "done": "Завершен",
        "new": "Создан",
        "running": "В обработке",
      },
      insurerMap: {
        "alfa": "Альфа",
        "renaissance": "Ренессанс",
      },
      processStatusMap: {
        "wait": "Ожидает",
        "enriching": "Обогащение",
        "enriched": "Обогащен",
        "ready_to_run": "В очереди на обработку",
        "running": "В обработке",
        "failed": "Ошибка",
        "completed": "Завершен",
        "rejected": "Отклонен контрагентом"
      }
    }
  },
  methods: {
    loadBatch() {
      axiosApiInstance.get(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/batches/" + this.$route.params.id)
          .then((response) => {
            this.reqCheck = response.data.status !== 'done' || response.data.status === "filed";

            this.batch = response.data
            this.loading = false
          }).catch(error => {
        console.log("view error ", error)
      });
    },
    navigateToProcesses(status) {
      router.push({name: 'Processes', query: {batchId: this.batch.id, status: status}})
    },
    formatDate(time) {
      if (time === undefined) {
        return ""
      }
      return moment(time).utc(true).format("YYYY-MM-DD")
    },
    formatDateTime(time) {
      if (time === undefined) {
        return ""
      }
      return moment(time).utc(true).format("YYYY-MM-DD HH:mm:ss")
    },
    humanizeStatus(status) {
      return this.statusMap[status]
    },
    humanizeInsurer(status) {
      return this.insurerMap[status]
    },
    humanizeProcessStatus(status) {
      return this.processStatusMap[status.status]
    },
  },
}
</script>

<style scoped>

.data {
  text-align: left;
}

</style>
