<template>
  <div>
    <el-form :inline="true" class="form-inline">
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input placeholder="Номер/VIN" v-model="filter.carIdentity" size="small"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input placeholder="Карта" v-model="filter.cardId" size="small"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input placeholder="ID транзакции" v-model="filter.txId" size="small"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input placeholder="ID контрагента" v-model="filter.contragentId" size="small"></el-input>
        </el-col>
        <el-col :span="2">
          Ошибки
          <el-checkbox value="true" placeholder="Ошибки" v-model="filter.failed" size="small"></el-checkbox>
        </el-col>
        <el-col :span="1">
          <el-button type="primary" size="small" plain @click="filterCalculations">Поиск</el-button>
        </el-col>
        <el-col :span="1">
          <el-button v-if="!excelBtnDisabled()" size="small" type="warning" plain @click="downloadExcel">Выгрузка
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <br/>
    <div>
      <el-table :data="calculations" style="width: 100%">
        <el-table-column prop="id" label="Идентификаторы транзакций" width="300">
          <template #default="scope">
            <div title="Id топливной транзакции"><b>{{ scope.row?.tx?.id }}</b></div>
            <div title="Id полиса">{{ scope.row.policy?.id }}</div>
            <div title="Id транзакции оплаты">{{ scope.row.transit?.commit?.TxId }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="Статус" width="150">
          <template #default="scope">
            <el-button style="font-size:12px" v-if="scope.row.error!==undefined && scope.row.error.length>0"
                       @click="displayError(scope.row)">
              {{ statusColumn(scope.row) }}
            </el-button>
            <span v-else>{{ statusColumn(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="contragentId" label="Идентификаторы машин и клиента" width="300">
          <template #default="scope">
            <div title="Номер карты">{{ scope.row.cardId }}</div>
            <div title="Контрагент">{{ scope.row.contragentId }}</div>
            <div title="Госномер">{{ scope.row.govNumber }}</div>
            <div title="VIN">{{ scope.row.vin }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="insuranceStartDateTimeFmt" label="Даты"
                         width="150">
          <template #default="scope">
            <div title="Время топливной транзакции"><b>{{ scope.row.insertedAtFmt }}</b></div>
            <div title="Начало периода страхования">{{ scope.row.insuranceStartDateTimeFmt }}</div>
            <div title="Конец периода страхования">{{ scope.row.insuranceEndDateTimeFmt }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="Цена/Объем" width="100">
          <template #default="scope">
            <div title="Цена"><b>{{ scope.row.price }}</b></div>
            <div title="Цена c ндс">{{ scope.row.ndsPrice }}</div>
            <div title="Объем">{{ scope.row.tx.amount }} л.</div>
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-button v-if="calculations[scope.$index].downloaded===true"
                       @click="downloadFile(calculations[scope.$index])"> {{ calculations[scope.$index].policyNumber }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <!--            <el-button size="small" type="danger"-->
            <!--                       @click="archive(calculations[scope.$index])">Архив-->
            <!--            </el-button>-->
            <el-button size="small" type="warning"
                       v-if="scope.row.error!==undefined && scope.row.error.length>0 && !scope.row.committed"
                       @click="retry(calculations[scope.$index])">Повтор
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br/>
      <el-pagination
          :page-size="meta.pageSize"
          :pager-count="11"
          layout="prev, pager, next"
          :total="meta.pageCount*meta.pageSize"
          @current-change="setPage"
          :current-page="meta.page"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import store from "../../store";
import moment from "moment/moment";
import {ElMessageBox} from "element-plus";
import axiosApiInstance from "@/axios";

function formatDateTime(time) {
  if (time === undefined) {
    return ""
  }
  return moment(time).utc(true).format("YYYY-MM-DD HH:mm:ss")
}

function formatUnixDateTime(time) {
  if (time === undefined) {
    return ""
  }
  return moment(time).format("YYYY-MM-DD HH:mm:ss");
}

function calcPercent(percent, num) {
  return (percent / 100) * num;
}

function getNdsPrice(insurancePrice) {
  if (insurancePrice === undefined) {
    return ""
  }
  let insurancePriceInt = parseInt(insurancePrice);
  let nds = calcPercent(20, insurancePriceInt)
  let Rub = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  });
  return Rub.format((insurancePriceInt + nds) / 100)
}

function getPrice(insurancePrice) {
  if (insurancePrice === undefined) {
    return ""
  }
  let insurancePriceInt = parseInt(insurancePrice);
  let Rub = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  });
  return Rub.format(insurancePriceInt / 100)
}

export default {
  name: 'CalculationsList',
  data: function () {
    return {
      filter: {page: 1, txId: "", cardId: "", contragentId: "", failed: false, carIdentity: ""},
      errorsMap: {
        10009: "Сервис не активен",
        10010: "Недопустимый тип топлива",
        10011: "Недопустмый время топливной транзакции",
        10012: "Не указаны идентификаторы автомобиля",
        10110: "Ошибка расчета",
        10013: "Недопустимый тип топлива",
        10014: "Блокировка по времени",
        10015: "Блокировка по лимиту покрытия",
        10200: "Ошибка запроса страхования",
        10300: "Сервис блокирован в транзите",
        10301: "Недостаточно средств",
        10302: "Транзакция в транзите закрыта",
        10305: "Транзакция в транзите уже существует",
        10308: "Ошибка холдирования в транзите",
        10310: "Неизвестная ошибка",
        10320: "Ошибка коммита в транзите",
        10330: "Ошибка отката в транзите",
      },
    }
  },
  computed: {
    calculations: function () {
      let list = store.getters.kaskoCalculations.data
      for (const key in list) {
        list[key].insuranceStartDateTimeFmt = formatDateTime(list[key].result.insuranceStartDateTime)
        list[key].insuranceEndDateTimeFmt = formatDateTime(list[key].result.insuranceEndDateTime)
        list[key].insertedAtFmt = formatUnixDateTime(list[key].insertedAt)
        list[key].ndsPrice = getNdsPrice(list[key].result.insurancePrice)
        list[key].price = getPrice(list[key].result.insurancePrice)
      }
      return list
    },
    meta: function () {
      return store.getters.kaskoCalculations.meta
    },
  },
  watch: {
    '$route.query': {
      handler(p) {
        this.applyQueryParamsToFilter(p)
      },
      immediate: true
    }
  },
  created: function () {
    store.commit('updateKaskoCalculations', this.filter)
  },
  methods: {
    statusColumn(row) {
      var status = ""
      if (row.committed) {
        status = "ОК"
      } else if (row.created) {
        status = "Создано"
      } else if (row.calculated) {
        status = "Калькуляция"
      } else if (row.errorCode === 10014) {
        status = "Лимит времени"
      } else if (row.failed) {
        status = "Ошибка"
      }
      if (!row.failed) {
        if (row.errorCode !== undefined) {
          if (status.length !== 0) {
            status += ": "
          }
          status += this.mappedError(row.errorCode)
        } else if (row.error !== undefined && row.error.length > 0) {
          status += " c ошибкой"
        }
      }

      return status
    },
    mappedError(calc) {
      if (calc.errorCode === undefined) {
        return ""
      }
      return this.errorsMap[calc.errorCode]
          ? (calc.errorCode + ":" + this.errorsMap[calc.errorCode])
          : calc.errorCode
    },
    displayError(calc) {
      ElMessageBox.alert(this.mappedError(calc) + ". " + calc.error, 'Ошибка при обработке транзакции', {
        confirmButtonText: 'Смириться'
      })
    },
    excelBtnDisabled() {
      return this.filter.cardId.length === 0 &&
          this.filter.carIdentity.length === 0 &&
          this.filter.txId.length === 0 &&
          this.filter.contragentId.length === 0
    },
    displayTransitInfo(calc) {
      let str = ""
      if (calc.transit.hold != null && calc.transit.hold !== 'undefined') {
        str += "Hold:" + calc.transit.hold.TxId + " " + calc.transit.hold.Message + "\n"
      }
      if (calc.transit.rollback != null && calc.rollback.hold !== 'undefined') {
        str += "Rollback:" + calc.transit.rollback.Message + "\n"
      }
      if (calc.transit.commit != null && calc.transit.commit !== 'undefined') {
        str += "Commit:" + calc.transit.commit.Message
      }
      ElMessageBox.alert(str, 'Информация об оплате транзакции', {
        confirmButtonText: 'Закрыть'
      })
    },
    applyQueryParamsToFilter(p) {
      if (!p) {
        p = {}
      }
      if (p.page && p.page.length > 0) {
        this.filter.page = p.page
      }
      if (p.txId && p.txId.length > 0) {
        this.filter.txId = p.txId
      }

      if (p.carIdentity && p.carIdentity.length > 10) {
        this.filter.carIdentity = p.carIdentity
      }
      if (p.cardId && p.cardId.length > 0) {
        this.filter.cardId = p.cardId
      }
      if (p.contragentId && p.contragentId.length > 0) {
        this.filter.contragentId = p.contragentId
      }
      this.filter.failed = p.failed === "true";
    },
    setPage(val) {
      this.filter.page = val
      this.$router
          .push({
            query: {
              ...this.$route.query,
              page: this.filter.page,
              txId: this.filter.txId,
              cardId: this.filter.cardId,
              carIdentity: this.filter.carIdentity,
              contragentId: this.filter.contragentId,
              failed: this.filter.failed.toString(),
            }
          })
          .catch(() => {
          });
      this.filterCalculations()
    },
    filterCalculations() {
      store.commit('updateKaskoCalculations', this.filter)
      this.$router
          .push({
            query: {
              ...this.$route.query,
              page: this.filter.page,
              txId: this.filter.txId,
              carIdentity: this.filter.carIdentity,
              cardId: this.filter.cardId,
              contragentId: this.filter.contragentId,
              failed: this.filter.failed.toString(),
            }
          })
          .catch(() => {
          });
    },
    formatDateTime(time) {
      if (time === undefined) {
        return ""
      }
      return moment(time).utc(true).format("YYYY-MM-DD HH:mm:ss")
    },
    downloadFile(calc) {
      axiosApiInstance.get(process.env.VUE_APP_KASKO_SERVICE_URL + "/api/v1/fuel/calculations/" + calc.id + "/policy")
          .then((response) => {
            console.log("file url ", response.data.url)
            window.location.href = response.data.url
          })
    },
    downloadExcel() {
      let govNumber = ""
      let vin = ""
      if (this.filter.carIdentity.length > 10) {
        vin = this.filter.carIdentity
      } else {
        govNumber = this.filter.carIdentity
      }

      const params = new URLSearchParams({
        cardId: this.filter.cardId,
        govNumber: govNumber,
        vin: vin,
        contId: this.filter.contragentId,
      });
      axiosApiInstance.get(process.env.VUE_APP_KASKO_SERVICE_URL + "/api/v1/fuel/calculations/excel?" + params.toString(), {
        responseType: 'blob',
      }).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
            'download',
            `${new Date().toLocaleDateString()}.xlsx`
        )
        document.body.appendChild(link)
        link.click()
      })
    },
    archive(calc) {
      if (confirm("Архивировать транзакцию " + calc.id + "? Это действие необратимо!")) {
        store.commit('archiveCalc', {
          id: calc.id,
          onArchive: () => {
            this.filterCalculations()
          },
          onError: () => {
            alert("Ошибка архивации")
            this.filterCalculations()
          }
        })
      }
    },
    retry(calc) {
      if (confirm("Запустить заново обработку транзакции " + calc.id + "? Это действие необратимо!")) {
        store.commit('retryCalc', {
          id: calc.id,
          onRetry: () => {
            this.filterCalculations()
          },
          onError: () => {
            alert("Ошибка обработки")
            this.filterCalculations()
          }
        })
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

</style>
