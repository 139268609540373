<script>

import axiosApiInstance from "../../axios";

export default {
  name: 'PolicyFileView',
  created() {
    console.log("created")
    this.downloadFile(this.$route.params.id)
  },
  methods: {
    downloadFile(calcId) {
      axiosApiInstance.get(process.env.VUE_APP_KASKO_SERVICE_URL + "/api/v1/fuel/calculations/" + calcId + "/policy")
          .then((response) => {
            console.log("file url ", response.data.url)
            window.location.href = response.data.url
          }).catch((err) => {
          if (err.response) {
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
            if (typeof err.response.data.errors !== 'undefined' && err.response.data.errors.length > 0) {
              alert(err.response.data.errors[0].detail)
            } else if (err.response.status === 404) {
              alert("Файл полиса не найден")
            } else if (typeof err.response.data.error !== 'undefined') {
              alert(err.response.data.error)
            }
          } else {
            alert(err)
          }
      })
    },
  },
}
</script>

