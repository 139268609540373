import Keycloak from "keycloak-js";

const keycloakInstance = new Keycloak({
    url: 'https://idp.transitcard.ru/',
    realm: 'corporate_internal',
    clientId: process.env.VUE_APP_KEY_CLOAK_RESOURCE,
    onLoad: 'login-required',
    resource: process.env.VUE_APP_KEY_CLOAK_RESOURCE,
    sslRequired: "external",
});

keycloakInstance.onTokenExpired = () => {
    console.log('token expired', keycloakInstance.token);
    keycloakInstance.updateToken(30).then(() => {
        console.log('successfully get a new token', keycloakInstance.token);
    }).catch(() => {
        console.log('error on getting new token');
    });
}


/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const Login = (onAuthenticatedCallback) => {
    keycloakInstance
        .init({onLoad: "login-required"})
        .then(function (authenticated) {
            authenticated ? onAuthenticatedCallback() : alert("non authenticated");
        })
        .catch((e) => {
            console.dir(e);
            console.log(`keycloak init exception: ${e}`);
        });
};

const KeyCloakService = {
    CallLogin: Login,
    Instance: keycloakInstance,
};

export default KeyCloakService;