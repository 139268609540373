<template>
  <div>

    <el-form :inline="true" class="form-inline">
      <el-row :gutter="20">
        <el-col :span="3" :xs="5">
          <el-button type="success" size="small" plain @click="createProcesses">Новая заявка</el-button>
        </el-col>
        <el-col :span="5">
          <el-input placeholder="Укажите идентификатор" v-model="filterValue" size="small"></el-input>
        </el-col>
        <el-col :span="4" :xs="6">
          <el-select v-model="filterType" class="m-2" size="small">
            <el-option
                v-for="item in filterTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-col>
        <el-col :span="2" :xs="4">
          <el-button type="primary" size="small" plain @click="filterProcesses">Поиск</el-button>
        </el-col>
        <el-col :span="3">
          <el-checkbox v-model="filter.my" size="small" label="Только свои"/>
        </el-col>

      </el-row>

    </el-form>
    <el-table :data="processes" style="width: 100%">
      <el-table-column>
        <template #default="scope">
          <el-button size="small" :type="processes[scope.$index].mocked?'warning':''" plain
                     @click="navigateProcess(processes[scope.$index])">Просмотр
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createdAtFmt" label="Дата"/>
      <el-table-column prop="status" :formatter="statusFormatter" label="Статус"/>
      <el-table-column prop="holderInn" label="Страхователь">
      </el-table-column>
      <el-table-column prop="carNumbers" label="Номер авто"/>
      <el-table-column prop="insurer" label="Страховая" :formatter="humanizeInsurer" width="180"/>
      <el-table-column prop="premium" label="Премия" width="180"/>
      <el-table-column>
        <template #default="scope">
          <el-button size="small"
                     @click="recalc(processes[scope.$index])">Перерасчет
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <br/>
    <el-pagination
        :page-size="meta.pageSize"
        :pager-count="11"
        layout="prev, pager, next"
        :total="meta.pageCount*meta.pageSize"
        @current-change="setPage"
    >
    </el-pagination>
  </div>
</template>

<script>
import store from "../store";
import router from "../router";
import moment from "moment/moment";

function formatDateTime(time) {
  if (time === undefined) {
    return ""
  }
  return moment(time).utc(true).format("YYYY-MM-DD HH:mm:ss")
}

export default {
  name: 'ProcessList',
  data: function () {
    return {
      filter: {page: 1, id: "", offerId: "", carNumber: "", holderInn: "", my: false, batchId: "", status: ""},
      insurerMap: {
        "alfa": "Альфа",
        "renaissance": "Ренессанс",
      },
      filterValue: "",
      filterType: "offerId",
      filterTypes: [
        {label: "Госномер", value: "carNumber"},
        {label: "Предложение", value: "offerId"},
        {label: "Пакет", value: "batchId"},
        {label: "Заявка", value: "id"},
      ],
      statusMap: {
        "wait": "в очереди",
        "ready_to_run": "ожидает запуска",
        "enriching": "обогащение",
        "enriched": "обогащена",
        "running": "обработка",
        "failed": "ошибка",
        "timeout": "таймаут",
        "completed": "завершено",
        "rejected": "отклонена"
      },

      errorMap: {
        10000: "Коды ошибок страхования ОСАГО",
        10001: "Ошибка запроса калькуляции",
        10005: "Несегмент (отказ страховой)",
        10006: "Таймаут валидации авто",
        10008: "Ошибка калькуляции",
        10010: "Ошибка добавления страхователя",
        10011: "Ошибка результата калькуляции",
        10012: "Таймаут калькуляции",
        10013: "Ошибка введенных данных (по машине, по адресам)",
        10015: "Ошибка запроса КБМ",
        10016: "Ошибка разбора результата КБМ",
        10017: "Страховая не пропускает по КБМ",
        10018: "Проблема в данных страхователя",
        10019: "Проблема в даных владельца",
        10020: "Ошибка добавления владельца",
        10025: "Ошибка оформления проекта страховки",
        10026: "Таймаут оформления проекта страховки",
        10030: "Ошибка получения файла счета",
        10031: "Таймаут получения файла счета",
        10035: "Ошибка активации полиса",
        10036: "Таймаут активации полиса",
        10037: "Таймаут получения файла полиса",
        10040: "Ошибка получения полиса",
        10042: "Ошибка получения документов",
        10045: "Таймаут получения документов",
        10110: "Данные начала полиса находятся в прошлом",
        10114: "Неправильный формат VIN",
        10115: "Неправильный формат серии документа авто",
        10116: "Неправильный формат номера документа авто",
        10117: "Неправильная дата документа авто",
        10118: "Неправильный производитель авто",
        10125: "Неправильный год производства авто",
        10130: "Мощность авто должна быть в пределах 17-2000",
        10135: "Неправильный формат госномера авто",
        10300: "Невозможно оформить страховку",
        52001: "Неизвестный город отправления груза",
        52002: "Неизвестный город прибытия груза",
        52003: "Недопустимая сумма покрытия груза",
        52004: "Недопустимая дата начала начала перевозки",
        52005: "Недопустимый маршрут перевозки",
        52006: "Грузоотправитель не найден по ИНН в сервисе организаций",
        53100: "Коды ошибок связанные с контрагентами",
        53101: "Неизвестный контрагент",
        54100: "Коды ошибок связанные с балансом",
        54101: "Недостаточно средств на балансе контрагента",
        51101: "Ошибка создания записи предложения ОСАГО",
        50250: "Инн владельца не найден в полисе осаго",
        50251: "Организация владельца не найдена",
        50252: "Инн страхователя не найден в полисе осаго",
        50253: "Организация страхователя  не найдена",
        50254: "Владелец не юрлицо",
        50255: "Страхователь не юрлицо",
        50000: "Сервис страховок не доступен",
        50001: "Владелец в черном списке",
        50002: "Страхователь в черном списке",
        50010: "Есть полис на следующий год",
        50101: "Неизвестная ошибка",
        50103: "Нельзя оформить осаго (например такси или мопед)",
        50105: "Неизвестный формат госномера",
      }
    }
  },
  computed: {
    processes: function () {
      let list = []
      for (const key in store.getters.processesData.data) {
        let val = store.getters.processesData.data[key]

        val.createdAtFmt = formatDateTime(val.createdAt)
        list.push(val)
      }
      return list
    },
    meta: function () {
      return store.getters.processesData.meta
    }
  },
  created: function () {
    store.commit('updateProcesses', this.filter)
  },
  methods: {
    setPage(val) {
      this.filter.page = val
      this.filterProcesses()
    },
    humanizeInsurer(row, column, status) {
      return this.insurerMap[status]
    },
    filterProcesses() {
      this.filter.carNumber = ""
      this.filter.offerId = ""
      this.filter.id = ""
      this.filter[this.filterType] = this.filterValue.trim()
      store.commit('updateProcesses', this.filter)
    },
    createPolicyByOffer(id, table) {
      store.commit('createPolicy', {
        offerId: table[id].id, onCreate: function (id) {
          router.push({name: 'Policy', params: {id: id}})
        }
      })
    },
    statusFormatter(row, col, val) {
      if (this.statusMap[val] !== undefined) {
        val = this.statusMap[val]
      }
      if (row['errorCode'] > 0) {
        if (this.errorMap[row['errorCode']]!==undefined) {
          return this.errorMap[row['errorCode']]
        }
        val += " " + row['errorCode']
      }
      return val
    },
    createProcesses() {
      router.push({name: 'ProcessCreate'})
    },
    navigateProcess(process) {
      router.push({name: 'ProcessView', params: {id: process.id}})
    },
    recalc(process) {
      router.push({name: 'ProcessCreate', params: {sourceId: process.id}})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.info-item {
  margin-bottom: 18px;
}
</style>
