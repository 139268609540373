import {reactive} from "vue";

import axiosApiInstance from "./axios";
import vuex from 'vuex'
import VuexPersistence from "vuex-persist";
import moment from "moment/moment";
import keycloak from "@/keycloak";

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});
const store = new vuex.Store({
    plugins: [vuexLocal.plugin],
    debug: true,

    state: reactive({
        wizardSearchResult: {},
        processes: {
            data: [],
            meta: {
                page: 1,
                pageSize: 20,
                pageCount: 0
            },
            filter: {
                page: 1,
                id: "",
                carNumber: "",
                offerId: "",
                my: false,
                insurer: "",
                status: "",
                batchId: ""
            }
        },
        kaskoCalculations: {
            data: [],
            meta: {
                page: 1,
                pageSize: 20,
                pageCount: 0
            },
            filter: {
                page: 1,
                txId: "",
                govNumber: "",
                vin: "",
                cardId: ""
            }
        },
        batches: {
            data: [],
            meta: {
                page: 1,
                pageSize: 20,
                pageCount: 0
            },
            filter: {
                page: 1,
                id: "",
                carNumber: "",
            }
        },
        osago: {
            registry: {
                policies: {
                    data: [],
                    meta: {
                        page: 1,
                        pageSize: 20,
                        pageCount: 0
                    },
                    filter: {
                        page: 1,
                        id: "",
                        govNumber: "",
                    }
                }
            },
            count: {
                data: [],
            }
        },
        policies: {
            data: [],
            meta: {
                page: 1,
                pageSize: 20,
                pageCount: 0
            },
            filter: {
                page: 1,
                id: "",
                carNumber: "",
                holderInn: "",
                my: false,
                insurer: "",
            }
        },
        cards: {
            data: [],
            meta: {
                page: 1,
                pageSize: 20,
                pageCount: 0
            },
            filter: {
                page: 1,
                id: "",
                govNumber: "",
                vin: "",
                contragentId: "",
                carIdentity:"",
            }
        },
        waybills: {
            data: [],
            meta: {
                page: 1,
                pageSize: 20,
                pageCount: 0
            },
            filter: {
                page: 1,
                id: "",
                contId: "",
                status: "",
                tripTicket: ""
            }
        }
    }),
    mutations: {
        searchOrg(state, payload) {
            let data = {
                "context": {
                    "initiator": "insurance-ui",
                    "scenario": "org-search",
                    "correlationId": uuidv4(),
                    "actorId": keycloak.Instance.tokenParsed.sub,
                    "timestamp": new Date().getTime()
                },
                "inn": payload.data.search,
                "storeResult": true
            }
            axiosApiInstance.post(process.env.VUE_APP_ORG_SERVICE_URL + "/api/v1/organizations/search", data).then((response) => {
                console.log("search org response ", response.data)
                payload.onCreate(true, response.data)
            }).catch(error => {
                console.log("search org error ", error)
                payload.onCreate(false, error.response)
            });
        },
        searchCar(state, payload) {
            axiosApiInstance.post(process.env.VUE_APP_CAR_SERVICE_URL + "/api/v1/cars", {
                "meta": {
                    "context": {
                        "initiator": "insurance-ui",
                        "scenario": "car-search",
                        "correlationId": uuidv4(),
                        "actorId": "" + keycloak.Instance.tokenParsed.sub,
                        "timestamp": new Date().getTime()
                    }
                },
                "data": {
                    "grz": {
                        "value": payload.data
                    }
                }
            }).then((response) => {
                console.log("search car ok ", response.data.data)
                payload.onCreate(true, response.data.data)
            }).catch(error => {
                console.log("search car error ", error)
                payload.onCreate(false, error.response)
            });
        },
        createPolicy(state, payload) {
            axiosApiInstance.post(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/policies", {
                "context": {
                    "initiator": "insurance-ui",
                    "scenario": "create-policy",
                    "correlationId": uuidv4(),
                    "actorId": "" + keycloak.Instance.tokenParsed.sub,
                    "timestamp": new Date().getTime()
                },
                "offerId": payload.offerId,
            }).then((response) => {
                console.log("create result ", response.data)
                payload.onCreate(response.data)
            }).catch((response) => {
                console.log("create error ", response.data)
                payload.onError(payload.data)
            })
        },
        fetchProcess(state, payload) {
            axiosApiInstance.get(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/processes/" + payload.data).then((response) => {
                payload.onSuccess(response.data)
            }).catch((e) => {
                console.log(e)
                alert("Ошибка загрузки данных")
            })
        },
        createProcess(state, payload) {
            axiosApiInstance.post(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/processes/full", {
                context: {
                    initiator: "osago_ui",
                    scenario: "offer-search",
                    correlationId: uuidv4(),
                    actorId: "" + keycloak.Instance.tokenParsed.sub,
                    timestamp: moment().utc(true).unix() * 1000
                },
                osagoData: {
                    insurers: payload.data.insurers,
                    params: payload.data.params,
                    owner: payload.data.owner,
                    holder: payload.data.holder,
                    cars: payload.data.cars,
                },
            }).then((response) => {
                console.log("create result ", response.data)
                payload.onCreate(true, response)
            }).catch(error => {
                console.log("create error ", error)
                payload.onCreate(false, error.response)
            });
        },
        createBatch(state, payload) {
            axiosApiInstance.post(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/batches", {
                list: payload.data.list,
            }).then((response) => {
                console.log("create result ", response.data)
                payload.onCreate(true, response)
            }).catch(error => {
                console.log("create error ", error)
                payload.onCreate(false, error.response)
            });
        },
        rejectProcess(state, payload) {
            console.log(payload)
            axiosApiInstance.post(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/processes/" + payload.id + "/reject", {
                meta: {
                    context: {
                        initiator: "osago_ui",
                        scenario: "offer-reject",
                        correlationId: uuidv4(),
                        actorId: "" + keycloak.Instance.tokenParsed.sub,
                        timestamp: moment().utc(true).valueOf()
                    }
                },
                data: {
                    type: payload.type,
                    comment: payload.comment,
                },
            }).then(() => {
                payload.onSuccess(payload)
            }).catch(error => {
                payload.onFail(false, error.response)
            });
        },
        activatePolicy(state, payload) {
            axiosApiInstance.post(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/policies/" + payload.policyId + "/activate", {
                "context": {
                    "initiator": "insurance-ui",
                    "scenario": "policy-activate",
                    "correlationId": uuidv4(),
                    "actorId": "" + keycloak.Instance.tokenParsed.sub,
                    "timestamp": new Date().getTime()
                }
            }).then((response) => {
                console.log("activate result ", response.data)
                payload.onActivate(payload.data)
            }).catch((response) => {
                console.log("activate error ", response.data)
                payload.onError(payload.data)
            })
        },
        cancelWaybill(state, payload) {
            const params = {
                "meta": {
                    "context": {
                        "initiator": "manual",
                        "scenario": "cargo_cancel",
                        "correlationId": uuidv4(),
                        "actorId": uuidv4(),
                        "timestamp": new Date().getTime()
                    }
                }
            };
            axiosApiInstance.post(process.env.VUE_APP_CARGO_SERVICE_URL + "/api/v1/waybills/" + payload.waybill.id + "/cancel", params).then(() => {
                payload.callback(true)
            }).catch(() => {
                payload.callback(false)
            })
        },
        updateProcesses(state, payload) {
            state.processes.meta.page = payload.page

            const params = new URLSearchParams({
                page: state.processes.meta.page,
                pageSize: state.processes.meta.pageSize,
                autorun: true
            });
            if (payload.offerId !== "") {
                params.append("offerId", payload.offerId)
            }
            if (payload.carNumber !== "") {
                params.append("carNumber", payload.carNumber)
            }
            if (payload.id !== "") {
                params.append("id", payload.id)
            }
            if (payload.my) {
                params.append("createdBy", keycloak.Instance.tokenParsed.sub)
            }
            if (payload.batchId) {
                params.append("batchId", payload.batchId)
            }
            if (payload.status) {
                params.append("status", payload.status)
            }

            axiosApiInstance.get(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/processes?" + params.toString()).then((response) => {
                state.processes = {
                    data: response.data.items,
                    meta: response.data.meta,
                    filter: state.processes.filter
                }
            }).catch(function (error) {
                alert("Error on request processes:" + error.data)
                console.log(error)
            })
        },
        clearOsagoRegistryPoliciesFilter(state) {
            console.log("clearOsagoRegistryPoliciesFilter")
            state.osago.registry.policies.filter = {
                page: 1,
                id: "",
                govNumber: "",
            }
        },
        clearOsagoRegistryPolicies(state) {
            console.log("clearOsagoRegistryPolicies")
            state.osago.registry.policies.data = []
        },
        updateOsagoRegistryPolicies(state, payload) {
            let policies = state.osago.registry.policies
            policies.meta.page = payload.page
            policies.filter.id = payload.id
            policies.filter.govNumber = payload.govNumber

            console.log('updateOsagoRegistryPolicies', policies.filter)

            const params = new URLSearchParams({
                page: policies.meta.page,
                pageSize: policies.meta.pageSize,
            });
            if (policies.meta.page !== undefined) {
                params.append("page", policies.meta.page)
            }
            if (policies.filter.id !== undefined && policies.filter.id !== '') {
                params.append("id", policies.filter.id)
            }
            if (policies.filter.govNumber !== undefined && policies.filter.govNumber !== '') {
                params.append("govNumber", policies.filter.govNumber)
            }

            axiosApiInstance.get(
                process.env.VUE_APP_OSAGO_REGISTRY_SERVICE_URL + "/api/v1/osago?" + params.toString()).then(
                (response) => {
                    state.osago.registry.policies = {
                        data: response.data.items,
                        meta: response.data.meta,
                        filter: policies.filter
                    }
                }).catch(function (error) {
                console.log(error)
            })
        },
        createOsagoRegistryPolicy(state, payload) {
            console.log(payload.data)
            axiosApiInstance.post(process.env.VUE_APP_OSAGO_REGISTRY_SERVICE_URL + "/api/v1/osago", {
                "meta": {
                    "context": {
                        "initiator": "insurance-ui",
                        "scenario": "create-osago-policy",
                        "correlationId": uuidv4(),
                        "actorId": uuidv4(),
                        "timestamp": new Date().getTime()
                    }
                },
                "data": payload.data,
            }).then((response) => {
                console.log("create result ", response.data)
                payload.onSuccess(response.data.id)
            }).catch((response) => {
                console.log("create error ", response.data)
                payload.onError(payload.data)
            })
        },
        createCard(state, payload) {
            axiosApiInstance.post(process.env.VUE_APP_KASKO_SERVICE_URL + "/api/v1/fuel/cards", {
                "data": payload.data,
            }).then((response) => {
                payload.onSuccess(response.data.data.id)
            }).catch(error => {
                if (error.response) {
                    payload.onError(error.response.data)
                } else {
                    console.log("Error", error);
                }
            });
        },
        updateCard(state, payload) {
            axiosApiInstance.put(process.env.VUE_APP_KASKO_SERVICE_URL + "/api/v1/fuel/cards/" + payload.id, {
                "data": payload.data,
            }).then((response) => {
                payload.onSuccess(response.data.data.id)
            }).catch((response) => {
                console.log("create error ", response)
                payload.onError(response)
            })
        },
        deleteCard(state, payload) {
            axiosApiInstance.delete(process.env.VUE_APP_KASKO_SERVICE_URL + "/api/v1/fuel/cards/"
                + payload.id)
                .then(() => {
                    payload.onDelete()
                }).catch((e) => {
                console.log("delete error ", e)
                payload.onError(e)
            })
        },
        archiveCalc(state, payload) {
            axiosApiInstance.post(process.env.VUE_APP_KASKO_SERVICE_URL + "/api/v1/fuel/calculations/"
                + payload.id + '/archive')
                .then(() => {
                    payload.onArchive()
                }).catch((e) => {
                console.log("archive error ", e)
                payload.onError(e)
            })
        },
        retryCalc(state, payload) {
            axiosApiInstance.post(process.env.VUE_APP_KASKO_SERVICE_URL + "/api/v1/fuel/calculations/"
                + payload.id + '/retry')
                .then(() => {
                    payload.onRetry()
                }).catch((e) => {
                console.log("retry error ", e)
                payload.onError(e)
            })
        },
        changeCardStatus(state, payload) {
            axiosApiInstance.put(process.env.VUE_APP_KASKO_SERVICE_URL + "/api/v1/fuel/cards/"
                + payload.id + "/status", {
                "data":
                    {
                        "active": payload.active
                    }
            })
                .then(() => {
                    payload.onChangeStatus()
                }).catch((e) => {
                console.log("activate/deactivate error ", e)
                payload.onError(e)
            })
        },
        deleteOsagoRegistryPolicy(state, payload) {
            const params = new URLSearchParams({
                initiator: "osago-ui",
                scenario: "policy_delete",
                correlationID: uuidv4(),
                actorId: "" + keycloak.Instance.tokenParsed.sub,
                timestamp: new Date().getTime()
            });
            axiosApiInstance.delete(process.env.VUE_APP_OSAGO_REGISTRY_SERVICE_URL + "/api/v1/osago/"
                + payload.id + "?" + params.toString())
                .then(() => {
                    payload.onDelete()
                }).catch((e) => {
                console.log("create error ", e)
                payload.onError(e)
            })
        },
        wizardSearchResult(state, payload) {
            state.wizardSearchResult = payload
        },
        updateOsagoRegistryPolicy(state, payload) {
            axiosApiInstance.put(process.env.VUE_APP_OSAGO_REGISTRY_SERVICE_URL + "/api/v1/osago/" + payload.id, {
                "meta": {
                    "context": {
                        "initiator": "insurance-ui",
                        "scenario": "create-osago-policy",
                        "correlationId": uuidv4(),
                        "actorId": uuidv4(),
                        "timestamp": new Date().getTime()
                    }
                },
                "data": payload.data,
            }).then((response) => {
                console.log("update result ", response.data)
                payload.onSuccess(response.data.id)
            }).catch((response) => {
                console.log("create error ", response)
                payload.onError(response)
            })
        },
        updateBatches(state, payload) {
            state.batches.meta.page = payload.page
            state.batches.filter.id = payload.id
            state.batches.filter.carNumber = payload.carNumber

            const params = new URLSearchParams({
                page: state.batches.meta.page,
                pageSize: state.batches.meta.pageSize,
                id: state.batches.filter.id,
                carNumber: state.batches.filter.carNumber,
            });
            axiosApiInstance.get(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/batches?" + params.toString()).then((response) => {
                state.batches = {
                    data: response.data.data,
                    meta: response.data.meta,
                    filter: state.batches.filter
                }
            }).catch(function (error) {
                console.log(error)
            })
        },
        updatePolicies(state, payload) {
            state.policies.meta.page = payload.page
            state.policies.filter.id = payload.id
            state.policies.filter.carNumber = payload.carNumber
            state.policies.filter.holderInn = payload.holderInn
            state.policies.filter.my = payload.my

            const params = new URLSearchParams({
                page: state.policies.meta.page,
                pageSize: state.policies.meta.pageSize,
                id: state.policies.filter.id,
                carNumber: state.policies.filter.carNumber,
                holderInn: state.policies.filter.holderInn,
            });
            if (payload.my) {
                params.append("createdBy", keycloak.Instance.tokenParsed.sub)
            }

            axiosApiInstance.get(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/policies?" + params.toString()).then((response) => {
                state.policies = {
                    data: response.data.items,
                    meta: response.data.meta,
                    filter: state.policies.filter
                }
            }).catch(function (error) {
                console.log(error)
            })
        },
        updateCards(state, payload) {
            state.cards.meta.page = payload.page
            state.cards.filter.id = payload.id
            state.cards.filter.govNumber = payload.govNumber
            state.cards.filter.vin = payload.vin
            state.cards.filter.contragentId = payload.contragentId

            const params = new URLSearchParams({
                page: state.cards.meta.page,
                pageSize: state.cards.meta.pageSize,
                id: state.cards.filter.id,
                govNumber: state.cards.filter.govNumber,
                vin: state.cards.filter.vin,
                contId: state.cards.filter.contragentId,
            });
            axiosApiInstance.get(process.env.VUE_APP_KASKO_SERVICE_URL + "/api/v1/fuel/cards?" + params.toString()).then((response) => {
                state.cards = {
                    data: response.data.data,
                    meta: response.data.meta,
                    filter: state.cards.filter
                }
            }).catch(function (error) {
                console.log(error)
            })
        },
        updateWaybills(state, payload) {
            state.waybills.meta.page = payload.page
            state.waybills.filter.id = payload.id
            state.waybills.filter.contId = payload.contId
            state.waybills.filter.status = payload.status
            state.waybills.filter.tripTicket = payload.tripTicket

            const params = new URLSearchParams({
                page: state.waybills.meta.page,
                pageSize: state.waybills.meta.pageSize,
                id: state.waybills.filter.id,
                contId: state.waybills.filter.contId,
                status: state.waybills.filter.status,
                tripTicket: state.waybills.filter.tripTicket,
            });

            axiosApiInstance.get(process.env.VUE_APP_CARGO_SERVICE_URL + "/api/v1/waybills?" + params.toString()).then((response) => {
                state.waybills = {
                    data: response.data.data,
                    meta: response.data.meta,
                    filter: state.waybills.filter
                }
            }).catch(function (error) {
                console.log(error)
            })
        },
        updateOsagoCount(state) {
            axiosApiInstance.get(
                process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/stats/counters").then(
                (response) => {
                    let data = []
                    data.push({
                        "carsCount": response.data.carsCount,
                        "osagoCount": response.data.osagoCount,
                        "osagoCountEnding": response.data.osagoCountEnding,
                        "invoicesCountByWeek": response.data.invoicesCountByWeek,
                        "invoicesCountByMonth": response.data.invoicesCountByMonth,
                        "countOffersByEnding": response.data.countOffersByEnding,
                    })
                    state.osago.count = {
                        data: data,
                    }
                }).catch(function (error) {
                alert(error)
                console.log(error)
            })
        },
        updateKaskoCalculations(state, payload) {
            console.log("updateKaskoCalculations")
            state.kaskoCalculations.meta.page = payload.page

            const params = new URLSearchParams({
                page: state.kaskoCalculations.meta.page,
                pageSize: state.kaskoCalculations.meta.pageSize
            });
            if (payload.carIdentity !== "") {
                if (payload.carIdentity.length > 10) {
                    params.append("vin", payload.carIdentity)
                }else{
                    params.append("govNumber", payload.carIdentity)
                }
            }

            if (payload.cardId !== "") {
                params.append("cardId", payload.cardId)
            }
            if (payload.txId !== "") {
                params.append("id", payload.txId)
            }
            if (payload.failed) {
                params.append("failed", payload.failed)
            }
            if (payload.contragentId !== "")  {
                params.append("contId", payload.contragentId)
            }

            axiosApiInstance
                .get(process.env.VUE_APP_KASKO_SERVICE_URL + "/api/v1/fuel/calculations?" + params.toString())
                .then((response) => {

                    console.log("updateKaskoCalculations")
                    state.kaskoCalculations = {
                        data: response.data.data,
                        meta: response.data.meta,
                        filter: state.kaskoCalculations.filter
                    }
                }).catch(function (error) {
                alert("Error on request calculations:" + error)
                console.log(error)
            })
        },
    },
    getters: {
        actorId: () => {
            return keycloak.Instance.tokenParsed.sub
        },
        authUrl: () => {
            return process.env.VUE_APP_AUTH_SERVICE_URL
        },
        processesData: state => {
            return state.processes
        },
        osagoRegistryPolicies: state => {
            return state.osago.registry.policies
        },
        batchesData: state => {
            return state.batches
        },
        policies: state => {
            return state.policies
        },
        cards: state => {
            return state.cards
        },
        waybills: state => {
            return state.waybills
        },
        wizardSearchResult: state => {
            return state.wizardSearchResult
        },
        osagoCount: state => {
            return state.osago.count
        },
        kaskoCalculations: state => {
            return state.kaskoCalculations
        },

    }
})

export default store
