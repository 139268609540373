<template>
  <div v-loading="loading">
    <h1>Запуск пакетной обработки</h1>

    <el-row :gutter="10">
      <el-col>
        <el-card id="holder">
          <el-alert v-if="errorMessage" style="text-align: left" show-icon type="error" title="Ошибка запуска"
                    :description="errorMessage"></el-alert>
          <br/>
          <el-upload style="display:inline-block;margin-right:10px" :auto-upload="false" :show-file-list="false"
                     :on-change="fileChange">
            <el-button type="primary">Выбрать файл</el-button>
          </el-upload>
          <el-button @click="clear" :disabled="this.loading || form.list.length<1">Очистить</el-button>
          <el-button type="primary" @click="submit()" :disabled="this.loading || form.list.length<1">
            Обработать номера:  {{form.list.length}}
          </el-button>
          <br/>
          <br/>
          <el-form ref="listForm" label-position="right" label-width="250px" :model="form">
            <el-row :gutter="20">
              <el-col :span="7">
                <el-input placeholder="Или введите номера вручную" v-model="text" type="textarea" @change="areaChange"/>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import store from "../store";
import router from '../router/index.js'

export default {
  name: 'BatchCreate',
  data: function () {
    let form = {
      listFile: null,
      list: [],
    }
    return {
      text: "",
      loading: false,
      errorMessage: "",
      form: form,
      emptyFor: JSON.parse(JSON.stringify(form)),
    }
  },
  methods: {
    submit() {
      this.loading = true
      store.commit('createBatch', {
        data: {
          list: this.form.list
        },
        onCreate: (success, response) => {
          if (success) {
            router.push({name: 'BatchView', params: {id: response.data.id}})
          } else {
            this.loading = false
            if (response.status === 422) {
              this.errorMessage = response.data.message
            } else {
              this.errorMessage = response.status + ": ошибка запуска"
            }
          }
        }
      })
    },
    clear() {
      this.form = {list: [], listFile: null}
    },
    fileChange(e) {
      if (e.raw.type !== "text/plain") {
        alert("Файл должен быть текстовым файлом .txt")
      }
      let fr = new FileReader();
      fr.readAsText(e.raw);
      fr.onload = () => {
        this.form.list = this.prepareData(fr.result)
      }
      return false
    },
    areaChange(e) {
      this.form.list = this.prepareData(e)
    },
    prepareData(data){
      return data.trim().split(/\r?\n/).filter((v, i, a) => a.indexOf(v) === i && v.length>5)
    }

  },
}
</script>

<style scoped>


</style>
