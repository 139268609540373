<template>
  <div >
    <h3>Полис {{ policy.govNumber }}</h3><br/>
    <el-row>
      <el-col :span="6" :xs="10">
        <div>
          <el-button style="width:150px" type="success" size="small" @click="edit(policy)" class="button">
            Редактировать
          </el-button>
          <br/>
          <br/>
          <el-button style="width:150px" size="small" :disabled="!policy.isActive &&  policy.number===''"
                     @click="downloadFile(policy)" class="button">
            Скачать документы
          </el-button>
        </div>
      </el-col>
      <el-col :span="10">
        <div>Номер: {{ policy.id }}</div>
        <div>Категория: {{ policy.category }}</div>
        <div>Даты действия: {{ formatDate(policy.startDate) }} - {{ formatDate(policy.endDate) }}</div>
        <div>Премия: {{ policy.premium }}</div>
        <div>Госномер ТС: {{ policy.govNumber }}</div>
        <div>Модель: {{ policy.model }}</div>
        <div>Производитель: {{ policy.maker }}</div>
        <div>Трейлер: {{ humanizeTrailer(policy.haveTrailer) }}</div>
        <div>Мощность: {{ policy.power }}</div>
        <div>Объем двигателя: {{ policy.volume }}</div>
        <div>Руль: {{ policy.wheel }}</div>
        <div>Год авто: {{ policy.year }}</div>
        <div>Город использования: {{ policy.cityOfUse }}</div>
        <div>Регион использования: {{ policy.regionOfUse }}</div>
        <div>Регион использования КЛАДР: {{ policy.regionOfUseKladr }}</div>
        <div>Страховая: {{ humanizeInsurer(policy.insurerName) }}</div>
        <div>Цвет: {{ policy.color }}</div>
        <div>Страхователь: {{ humanizeParty(policy.holderType) }} {{ policy.holderINN }} {{
            policy.holderName
          }}
        </div>
        <div>Владелец: {{ humanizeParty(policy.ownerType) }} {{ policy.ownerINN }} {{ policy.ownerName }}</div>
        <div>Номер полиса: {{ policy.series + "" + policy.number }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import router from "../../../router";
import moment from "moment";
import axiosApiInstance from "../../../axios";

export default {
  name: 'OsagoRegistryPolicyView',
  mounted() {
    this.loadPolicy()
  },
  data: function () {
    return {
      reqCheck: false,
      reqCheckPercentage: 100,
      loading: true,
      policy: {},
      checkInterval: null,
      statusMap: {
        "new": "Ожидает",
        "creating": "Создается",
        "created": "Создан",
        "create_failed": "Ошибка создания",
        "activating": "Активируется",
        "activated": "Активен",
        "activation_failed": "Ошибка активации"
      },
      insurerMap: {
        "alfa": "Альфа",
        "renaissance": "Ренессанс",
      },
      partyMap: {
        "LEGAL": "Орг.",
        "PERSON": "Физ. лицо",
        "UNKNOWN": "Неизвестно",
      },
    }
  },
  methods: {
    loadPolicy() {
      axiosApiInstance.get(process.env.VUE_APP_OSAGO_REGISTRY_SERVICE_URL + "/api/v1/osago/" + this.$route.params.id)
          .then((response) => {
            this.policy = response.data
          }).catch(error => {
        console.log("view error ", error)
      });
    },
    formatDate(time) {
      if (time === undefined) {
        return ""
      }
      return moment(time).utc(true).format("YYYY-MM-DD")
    },
    humanizeStatus(status) {
      return this.statusMap[status]
    },
    humanizeInsurer(status) {
      if (this.insurerMap[status] === undefined) {
        return status
      }
      return this.insurerMap[status]
    },
    humanizeTrailer(ok) {
      if (ok) {
        return "Есть"
      }
      return "Нет"
    },
    humanizeParty(status) {
      if (this.partyMap[status] === undefined) {
        return status
      }
      return this.partyMap[status]
    },
    edit(policy) {
      router.push({name: 'OsagoRegistryPolicyEdit', params: {id: policy.id}})
    },
    downloadFile(policy) {
      axiosApiInstance.get(process.env.VUE_APP_OSAGO_REGISTRY_SERVICE_URL + "/api/v1/osago/" + policy.id + "/policy")
          .then((response) => {
            console.log("file url ", response.data.url)
            window.location.href = response.data.url
          })
    }
  },
}
</script>

<style scoped>


</style>
