<template>
  <div>
    <el-row>
      <el-col v-if="mode==='start' || mode==='search'|| mode==='error'" :offset="7" :span="12">
        <el-card class="box-card" style="width:500px"
                 shadow="never">
          <el-row :gutter="4">
            <el-col :span="18">
              <el-input :disabled="mode==='search'" v-model="govNumber" placeholder="Госномер"></el-input>
              <el-progress v-if="mode==='search'" :indeterminate="true" :percentage="50" :show-text="false"/>
            </el-col>
            <el-col :span="6">
              <el-button v-if="mode==='start'" plain type="primary" @click="startSearch">Поиск</el-button>
              <span v-if="mode==='search'">
              <el-button plain @click="stopSearch">Остановить</el-button>
              </span>
            </el-col>
          </el-row>
          <div v-if="searchError!==''">
            <br/>
            <el-alert :closable="false" type="error">{{ searchError }}</el-alert>
          </div>
        </el-card>
      </el-col>
      <el-col v-if="mode==='result'" :offset="2" :span="20">
        <el-card class="box-card" shadow="never">
          <template #header>
            <div class="card-header">
              <span>Результат поиска {{ govNumber }}</span>
            </div>
          </template>
          <table>
            <tr v-if="searchData.holder.inn!==undefined && searchData.holder.inn!==''">
              <td>Страхователь:</td>
              <td>{{ searchData.holder.inn }} {{ searchData.holder.name }}</td>
            </tr>
            <tr v-if="searchData.owner.inn!==undefined && searchData.owner.inn!==''">
              <td>Владелец:</td>
              <td>{{ searchData.owner.inn }} {{ searchData.owner.name }}</td>
            </tr>
            <tr v-if="searchData.cars[0]!==undefined && searchData.cars[0].dateStart!==undefined">
              <td>Полис заканчивается:</td>
              <td> {{ formatDate(searchData.cars[0].dateStart) }}</td>
            </tr>
            <tr v-if="searchData.cars[0].vin!==undefined">
              <td>VIN:</td>
              <td>{{ searchData.cars[0].vin }}</td>
            </tr>
            <tr v-if="searchData.cars[0].power!==undefined">
              <td>Мощность:</td>
              <td>{{ searchData.cars[0].power }}</td>
            </tr>
            <tr v-if="searchData.cars[0].category!==undefined">
              <td>Категория:</td>
              <td>{{ searchData.cars[0].category }}</td>
            </tr>
            <tr v-if="searchData.cars[0].maker!==undefined">
              <td>Производитель:</td>
              <td>{{ searchData.cars[0].maker }}</td>
            </tr>
            <tr v-if="searchData.cars[0].model!==undefined">
              <td>Модель:</td>
              <td>{{ searchData.cars[0].model }}</td>
            </tr>
            <tr v-if="searchData.cars[0].year!==undefined">
              <td>Год:</td>
              <td>{{ searchData.cars[0].year }}</td>
            </tr>
            <tr v-if="searchData.cars[0].ptsNumber!==undefined">
              <td>ПТС:</td>
              <td>{{ searchData.cars[0].ptsSeries }} {{ searchData.cars[0].ptsNumber }} от
                {{ formatDate(searchData.cars[0].ptsDate) }}
              </td>
            </tr>
            <tr v-if="searchData.cars[0].stsDate!==undefined">
              <td> СТС:</td>
              <td> {{ searchData.cars[0].stsSeries }} {{ searchData.cars[0].stsNumber }} от
                {{ formatDate(searchData.cars[0].stsDate) }}
              </td>
            </tr>
          </table>

          <div v-if="searchError!==''">
            <br/>
            <el-alert :closable="false" type="error">Обнаружена проблема:{{ searchError }}</el-alert>
          </div>

          <br/>
          <el-button plain @click="createOsagoRegistryPolicy">Создать полис в реестре ОСАГО</el-button>
          <el-button plain @click="stopSearch">Новый поиск</el-button>
        </el-card>
        <br/>
        <el-card v-if="searchData.cars.length>0 " class="box-card" shadow="never">
          <template #header>
            <div class="card-header">
              <span>Существующие полисы в реестре осаго {{ govNumber }}</span>
            </div>
          </template>

          <policy-table :policies-list="policiesList.data"
                        :filter="{}"
                        :meta="policiesList.meta"
                        :view-button="false"
                        :delete-button="false"
                        :pagination="false"/>

        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import axiosApiInstance from "@/axios";
import moment from "moment/moment";
import store from "@/store";
import PolicyTable from "@/components/osago/registry/PolicyTable";

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function prepareNumber(str) {
  let list = {
    'a': 'а',
    'b': 'в',
    'e': 'е',
    'k': 'к',
    'm': 'м',
    'h': 'н',
    'o': 'о',
    'p': 'р',
    'c': 'с',
    't': 'т',
    'y': 'у',
    'x': 'х',
  }
  str = str.toLowerCase()
  for (const x in list) {
    str = str.replaceAll(x, list[x]);
  }
  return str
}

const regexpList = [
  new RegExp(`^([авекномрстух]{1,3}\\d{3}[авекномрстух]{0,2})([17]?\\d{2})$`, 'm'),
  new RegExp(`^(\\d{3,4}[авекномрстух]{1,2})([17]?\\d{2})$`, 'm'),
  new RegExp(`^([авекномрстух]{2}\\d{3})([17]?\\d{2})$`, 'm'),
  new RegExp(`^([авекномрстух]{2}\\d{4})([17]?\\d{2})$`, 'm'),
  new RegExp(`^([авекномрстух]{2}\\d{2,3}[авекномрстух]{1,2})([17]?\\d{2})$`, 'm'),
  new RegExp(`^([авекномрстух]\\d{4})([17]?\\d{2})$`, 'm'),
  new RegExp(`^(\\d{3,4}[авекномрстух]\\d{3})([17]?\\d{2})$`, 'm'),
  new RegExp(`^(\\d{5})([17]?\\d{2})$`, 'm'),
  new RegExp(`^(\\d{4}cd\\d)([17]?\\d{2})$`, 'm')
]

function validateNumber(number) {
  console.log(number)
  for (const key in regexpList) {
    if (regexpList[key].exec(number) !== null) {
      return true
    }
  }
  return false
}

export default {
  name: 'WizardView',
  components: {'policy-table': PolicyTable},
  data: function () {
    return {
      mode: "start",
      govNumber: "",
      searchError: "",
      searchActive: false,
      searchInterval: null,
      searchProcessId: null,
      searchData: null,
      checkCount: 0,
      errorMap: {
        50000: "Сервис не доступен",
        50001: "Организация владельца блокирована страховой",
        50002: "Организация страхователя блокирована страховой",
        50010: "Есть полис активный далеко в будущем",
        50101: "Неизвестная ошибка поиска",
        50105: "Неправильный формат госномера",
        50250: "Данные владельца не найдены в полисе",
        50251: "Организация владельца не указана в полисе",
        50252: "Организация страхователя не указана в полисе",
        50253: "Полис не найден",
        50254: "Владелец не является юрлицом",
        50255: "Страхователь не является юрлицом",
        50301: "Невозможно заполнить все поля данных",
      }
    }
  },
  unmounted() {
    this.changeMode('start', true)
  },
  computed: {
    policiesList: () => {
      return store.getters.osagoRegistryPolicies
    }
  },
  methods: {
    loadOsagoPoliciesList(govNumber) {
      store.commit('updateOsagoRegistryPolicies', {page: 1, govNumber: govNumber, id: ""})
    },
    validateGovNumber(number) {
      if (validateNumber(number)) {
        return true;
      }
      this.searchError = 'Неизвестный формат госномера'
      return false
    },
    formatDate(time) {
      if (time === undefined) {
        return ""
      }
      return moment(time).utc(true).format("YYYY-MM-DD")
    },
    changeMode(mode, clearInterval) {
      this.mode = mode
      if (clearInterval === true) {
        window.clearInterval(this.searchInterval)
      }
    },
    checkSearch() {
      axiosApiInstance.get(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/processes/" + this.searchProcessId)
          .then((r) => {
            if (r.data.status === "failed") {

              this.searchError = "код "
              if (this.errorMap[r.data.errorCode] !== undefined) {
                this.searchError += this.errorMap[r.data.errorCode]
              } else {
                this.searchError += r.data.errorCode
              }
              if (r.data.error !== undefined) {
                this.searchError += " " + r.data.error
              }

              this.searchData = JSON.parse(r.data.data)
              if (this.searchData.osagoData === undefined) {
                this.searchData = {}
                this.changeMode('error', true)
              } else {
                this.searchData = this.searchData.osagoData
              }
              this.changeMode('result', true)

              store.commit('clearOsagoRegistryPoliciesFilter')
            }
            if (r.data.status === "enriched") {
              this.searchData = JSON.parse(r.data.data)
              if (this.searchData.osagoData === undefined) {
                this.searchData = {}
              } else {
                this.searchData = this.searchData.osagoData
              }
              this.changeMode('result', true)
              this.loadOsagoPoliciesList(this.govNumber)
              store.commit('clearOsagoRegistryPoliciesFilter')
            }
            this.checkCount++
            if (this.checkCount > 1000) {
              this.checkCount = 0
              this.changeMode('error', true)
              this.searchError = "Таймаут"
              store.commit('clearOsagoRegistryPoliciesFilter')
              store.commit('clearOsagoRegistryPolicies')
            }
          })
          .catch(error => {
            console.log("check error ", error)
            this.searchError = error
            this.changeMode('error', true)
            store.commit('clearOsagoRegistryPoliciesFilter')
            store.commit('clearOsagoRegistryPolicies')
          });
    },
    stopSearch() {
      this.changeMode('start', true)
      this.searchError = ""
    },
    createOsagoRegistryPolicy() {
      this.$store.commit('wizardSearchResult', this.searchData)
      this.changeMode('start', true)
      this.searchError = ""
      this.$router.push({name: 'OsagoRegistryPolicyCreate', query: {fromSearch: true}})
    },
    clear() {
      this.govNumber = ''
    },
    startSearch() {
      this.govNumber = this.govNumber.trim()
      if (this.govNumber === '') {
        return
      }
      this.govNumber = prepareNumber(this.govNumber)
      if (!this.validateGovNumber(this.govNumber)) {
        return;
      }

      store.commit('clearOsagoRegistryPolicies')
      this.changeMode('search', false)
      this.searchError = ""
      let body = {
        context: {
          initiator: "osago_ui",
          scenario: "enrich",
          correlationId: uuidv4(),
          actorId: this.$store.getters.actorId + "",
          timestamp: moment().utc(true).unix() * 1000
        },
        data: {
          enrich: true,
          run: false,
          osago: {
            data: {
              insurers: ["alfa"],
              cars: [
                {
                  govNumber: this.govNumber
                }
              ]
            }
          }
        },
      }
      axiosApiInstance.post(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/processes", body).then((response) => {
        console.log("start result ", response.data)
        this.searchProcessId = response.data.requestId
        this.searchInterval = window.setInterval(this.checkSearch, 5000)
      }).catch(error => {
        console.log("start error ", error)
        this.searchError = error
        this.changeMode('error', true)
      });
    }
  },
}
</script>

<style scoped>

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>
