<template>
  <div class="data">
    <div>
      <div v-if="waybill.id">
        <h3>Груз {{ waybill.id }}</h3><br/>
        <br>
        <br>
        <el-row>
          <el-col :span="10">
            <div>Идентификаторы {{ idColumn(waybill) }}</div>
            <div>Создан/Начало действия {{ formatDatesColumn(waybill) }}</div>
            <div> Страхователь/Накладная {{ formatContIdColumn(waybill) }}</div>
            <div> Направление {{ formatCity(waybill) }}</div>
            <div> Покрытие {{ formatCoverColumn(waybill) }}</div>
            <div> Статус {{ formatStatusColumn(waybill, waybill.status) }}</div>
            <div v-if="waybill.error"> Ошибка {{ waybill.error }}</div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>

import axiosApiInstance from "../axios";
import moment from "moment";

export default {
  name: 'WaybillView',
  mounted() {
    this.loadWaybill()
  },
  data: function () {
    return {
      waybill: {},
      statusMap: {
        "new": "Калькуляция",
        "registering": "В процессе",
        "registered": "Зарегистрирован",
        "failed": "Ошибка",
        "deleted": "Удален",
        "cancelled": "Отменен",
        "cancelling": "Отменяется",
        "cancel_failed": "Ошибка отмены",
      }
    }
  },
  methods: {
    loadWaybill() {
      axiosApiInstance.get(process.env.VUE_APP_CARGO_SERVICE_URL + "/api/v1/waybills/" + this.$route.params.id)
          .then((response) => {
            console.log(response)
            this.waybill = response.data
          }).catch(error => {
        console.log("view error ", error)
      });
    },
    idColumn(row) {
      let str = row.id
      if (row.number !== undefined) {
        str += "\n" + row.number
      }
      if (row.paymentTransactionId !== undefined) {
        str += "\n" + row.paymentTransactionId
      }
      return str;
    },
    formatDatesColumn(row) {
      let str = ""
      if (row.createdAt !== "" && row.createdAt !== null) {
        str += moment(row.createdAt).utc(true).format("YYYY-MM-DD HH:mm:ss")
      }
      if (row.dateStart !== "" && row.dateStart !== null) {
        str += "\n" + moment(row.dateStart).utc(true).format("YYYY-MM-DD")
      }
      return str
    },
    formatContIdColumn(row) {
      if (row.tripTicket === undefined) {
        return row.contId
      }
      return row.contId + "\n" + row.tripTicket
    },
    formatCity(row) {
      return row.fromCityName + " -> " + row.toCityName
    },
    formatCoverColumn(row) {
      return row.cover + "\n" + row.premium
    },
    formatStatusColumn(row, val) {
      return this.statusMap[val] + "\n" + row.paymentStatus;
    },
  },
}
</script>

<style scoped>

.data {
  text-align: left;
}

</style>
