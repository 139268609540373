<template>
  <div>

    <el-form :inline="true" class="form-inline">
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input  v-model="filter.id" size="small" placeholder="Id"></el-input>
        </el-col>
        <el-col :span="4" :xs="5">
          <el-input v-model="filter.carNumber" size="small" placeholder="Госномер"></el-input>
        </el-col>
        <el-col :span="4" :xs="5">
          <el-input v-model="filter.holderInn" size="small" placeholder="Инн страхователя"></el-input>
        </el-col>
        <el-col :span="2" :xs="4">
          <el-button type="primary" size="small" plain @click="filterList">Поиск</el-button>
        </el-col>
        <el-col :span="3">
          <el-checkbox   v-model="filter.my" size="small" label="Только мои"/>
        </el-col>

      </el-row>

    </el-form>
    <el-table :data="policies" style="width: 100%">
      <el-table-column>
        <template #default="scope">
          <el-button size="small" :type="policies[scope.$index].mocked?'warning':''" plain
                     @click="navigatePolicy(policies[scope.$index])">Просмотр
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="status" :formatter="statusFormatter" label="Статус"/>
      <el-table-column prop="createdAt" :formatter="formatDateTime" label="Дата"/>
      <el-table-column prop="holderInn" label="Страхователь" width="180">
      </el-table-column>
      <el-table-column prop="carNumbers" label="Номер авто"/>
      <el-table-column prop="insurer" label="Страховая" width="180"/>
      <el-table-column prop="premium" label="Премия" width="180"/>
    </el-table>
    <br/>
    <el-pagination
        :page-size="meta.pageSize"
        :pager-count="11"
        layout="prev, pager, next"
        :total="meta.pageCount*meta.pageSize"
        @current-change="setPage"
    >
    </el-pagination>
  </div>
</template>

<script>


import store from "../store";
import moment from "moment";

export default {
  name: 'PolicyList',
  data: function () {
    return {
      filter: {page: 1, id: "", carNumber: "", holderInn: "", my: false},
      insurerMap: {
        "alfa": "Альфа",
        "renaissance": "Ренессанс",
      },
    }
  },
  computed: {
    policies: function () {
      return this.$store.getters.policies.data
    },
    meta: function () {
      return this.$store.getters.policies.meta
    }
  },
  created: function () {
    this.filter = store.getters.policies.filter
    this.$store.commit('updatePolicies', this.filter)
  },
  methods: {
    humanizeInsurer(status) {
      return this.insurerMap[status]
    },
    setPage(val) {
      this.filter.page = val
      this.filterList()
    },
    filterList() {
      this.filter.carNumber = this.filter.carNumber.trim()
      this.filter.holderInn = this.filter.holderInn.trim()
      this.$store.commit('updatePolicies', this.filter)
    },
    navigatePolicy(policy) {
      this.$router.push({name: 'PolicyView', params: {id: policy.id}})
    },
    statusFormatter(row, col, val) {
      return val
    },
    formatDateTime(row, col, val) {
      if (val === undefined) {
        return ""
      }
      return moment(val).utc(true).format("YYYY-MM-DD")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.info-item {
  margin-bottom: 18px;
}
</style>
