<template>
  <div>
    <el-form :inline="true" class="form-inline">
      <el-row :gutter="20">
        <el-col :span="4" :xs="8">
          <el-button size="small" type="warning" plain @click="createPolicy">Создать полис</el-button>
        </el-col>
        <el-col :span="8">
          <el-input size="small" v-model="filter.id" placeholder="Идентификатор"></el-input>
        </el-col>
        <el-col :span="8">
          <el-input size="small" v-model="filter.govNumber" placeholder="Номер авто"></el-input>
        </el-col>
        <el-col :span="3">
          <el-button size="small" type="primary" plain @click="filterPolicies(this.filter)">Поиск</el-button>
        </el-col>

      </el-row>

    </el-form>
    <policy-table :policies-list="policiesData"
                  :filter="filter"
                  :meta="policiesMeta"
                  :view-button="true"
                  :delete-button="true"
                  :pagination="true"
                  @onPagination="filterPolicies"/>
  </div>
</template>

<script>
import store from "../../../store";
import PolicyTable from "./PolicyTable";
import router from "../../../router";

export default {
  components: {'policy-table': PolicyTable},
  name: 'OsagoRegistryPoliciesList',
  data: function () {
    return {
      filter: {page: 1, id: "", govNumber: ""},
      insurerMap: {
        "alfa": "Альфа",
        "renaissance": "Ренессанс",
      },
    }
  },
  computed: {
    policiesData: function () {
      return store.getters.osagoRegistryPolicies.data
    },
    policiesMeta: function () {
      return store.getters.osagoRegistryPolicies.meta
    }
  },
  created: function () {
    this.filter = store.getters.osagoRegistryPolicies.filter
    store.commit('updateOsagoRegistryPolicies', this.filter)
  },
  methods: {
    filterPolicies(filter) {
      store.commit('updateOsagoRegistryPolicies', filter)
    },
    createPolicy() {
      router.push({name: 'OsagoRegistryPolicyCreate'})
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.info-item {
  margin-bottom: 18px;
}
</style>
