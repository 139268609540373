<template>
  <div>
    <el-table :data="policies" style="width: 100%">
      <el-table-column v-if="viewButton">
        <template #default="scope">
          <el-button size="small" :type="policies[scope.$index].mocked?'warning':''" plain
                     @click="navigateProcess(policies[scope.$index])">Просмотр
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createdAtStr" label="Дата"/>
      <el-table-column prop="govNumber" label="Номер авто"/>
      <el-table-column prop="insurerName" label="Страховая" width="180"/>
      <el-table-column>
        <template #default="scope">
          <el-button size="small"
                     @click="edit(policies[scope.$index])">Редактировать
          </el-button>
          <el-button v-if="deleteButton" size="small" type="danger"
                     @click="del(policies[scope.$index])">Удалить
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <br/>
    <el-pagination v-if="pagination"
                   :page-size="meta.pageSize"
                   :pager-count="11"
                   layout="prev, pager, next"
                   :total="meta.pageCount*meta.pageSize"
                   @current-change="setPage"
    >
    </el-pagination>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import moment from "moment";


function formatDate(time) {
  if (time === undefined) {
    return ""
  }
  return moment(time).utc(true).format("YYYY-MM-DD")
}

function prepareNumber(str) {
  let list = {
    'a': 'а',
    'b': 'в',
    'e': 'е',
    'k': 'к',
    'm': 'м',
    'h': 'н',
    'o': 'о',
    'p': 'р',
    'c': 'с',
    't': 'т',
    'y': 'у',
    'x': 'х',
  }
  str = str.toLowerCase().trim()
  for (const x in list) {
    str = str.replaceAll(x, list[x]);
  }
  return str
}


export default {
  name: "policy-table",
  props: ['filter', 'policiesList', 'meta', 'pagination', 'deleteButton', 'viewButton'],
  data: function () {
    return {
      insurerMap: {
        "alfa": "Альфа",
        "renaissance": "Ренессанс",
      },
    }
  },
  computed: {
    policies: function () {
      let list = []
      let data = this.policiesList
      for (const key in data) {
        let val = data[key]
        val.createdAtStr = formatDate(val.startDate) + " > " + formatDate(val.endDate)
        list.push(val)
      }
      return list
    },
  },
  methods: {
    navigateProcess(process) {
      router.push({name: 'OsagoRegistryPolicyView', params: {id: process.id}})
    },
    setPage(page) {
      let filter = this.filter
      filter.page = page
      this.filterPolicies(filter)
    },
    filterPolicies(filter) {
      if (filter.govNumber !== '') {
        filter.govNumber = prepareNumber(filter.govNumber)
      }
      if (filter.id !== '' && !/^[\da-f]{8}-[\da-f]{4}-[0-5][\da-f]{3}-[089ab][\da-f]{3}-[\da-f]{12}$/i.test(filter.id)) {
        alert("Неправильный формат идентификатора")
        return
      }
      console.log("paginate", filter)
      this.$emit('onPagination', filter)
    },
    statusFormatter(row, col, val) {
      if (row['errorCode'] === 50010) {
        return "Есть полис"
      }
      return val
    },
    edit(process) {
      router.push({name: 'OsagoRegistryPolicyEdit', params: {id: process.id}})
    },
    del(policy) {
      if (confirm("Удалить полис " + policy.govNumber + " " + formatDate(policy.startDate) + "?")) {

        store.commit('deleteOsagoRegistryPolicy', {
          id: policy.id,
          onDelete: () => {
            this.filterPolicies(this.filter)
          },
          onError: () => {
            alert("Ошибка удаления")
            this.filterPolicies(this.filter)
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>