import axios from 'axios'
import keycloak from "@/keycloak";

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(function (config) {
    config.headers.Authorization = "Bearer " + keycloak.Instance.token;
    return config;
});

export default axiosApiInstance