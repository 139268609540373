<template>
  <div class="data">
    <div>
      <el-progress v-if="reqCheck" indeterminate="indeterminate" :percentage="reqCheckPercentage"
                   style="margin-bottom: 20px"
                   :show-text="false"/>
      <div v-if="policy.id">

        <h3>Полис {{ policy.carNumbers }}</h3><br/>
        <el-row>
          <el-col :span="5">
            <div>
              <el-button style="width:150px" size="small" :disabled="!policy.haveInvoiceFile"
                         @click="downloadFile(policy,'invoice')" class="button">
                Счет
              </el-button>
              <br/><br/>
              <el-button style="width:150px" size="small" :disabled="!policy.havePurchaseFile"
                         @click="downloadFile(policy,'purchase')" class="button">
                Подтверждение
              </el-button>
              <br/><br/>
              <el-button style="width:150px" size="small" :disabled="!policy.havePolicyFile"
                         @click="downloadFile(policy,'policy')" class="button">
                Номера полисов
              </el-button>
              <br/><br/>
              <el-button style="width:150px" size="small" :disabled="!policy.isActive"
                         @click="downloadFile(policy,'result')" class="button">
                Документы
              </el-button>
            </div>
          </el-col>
          <el-col :span="10">
            <div>Номер: {{ policy.id }}</div>
            <div>Создан: {{ formatDateTime(policy.createdAt) }}</div>
            <div>Статус: {{ humanizeStatus(policy.status) }}</div>
            <div v-if="policy.error">Ошибка: {{ policy.errorCode }} {{ policy.error }}</div>
            <div>Даты действия: {{ formatDate(policy.dateStart) }} - {{ formatDate(policy.dateEnd) }}</div>
            <div v-if="policy.premium > 0">Премия: {{ policy.premium }}</div>
            <div>Госномер ТС: {{ policy.carNumbers }}</div>
            <div>Страховая: {{ humanizeInsurer(policy.insurer) }}</div>
            <div v-if="policy.insurerProcessId">Идентификатор процесса в страховой: {{ policy.insurerProcessId }}</div>
            <div>Инн страхователя: {{ policy.holderInn }}</div>
            <div>Номер полиса: {{ getPolicyNumbers() }}</div>
            <div>Заявка:
              <router-link :to="'/osago/processes/'+policy.processId">{{ policy.processId }}</router-link>
            </div>
            <br/>
            <el-button v-if="recreatable(policy)" @click="createPolicy()" class="button" type="danger">Повторить попытку
              создания
            </el-button>
            <el-button v-if="policyCreated(policy)" :disabled="!policy.isActivable" @click="activatePolicyByOffer()"
                       class="button" type="success">
              <span v-if="policy.isActivable">Активировать до {{ formatDate(policy.expired) }}</span>
              <span v-else>Активация невозможна после {{ formatDate(policy.expired) }}</span>

            </el-button>

          </el-col>
        </el-row>
      </div>

      <div v-if="policy.payments && policy.payments.length>0 ">
        <h3>Платежные документы</h3>
        <div v-for="payment in policy.payments" :key="payment.id">
          <el-row>
            <el-col :span="3">
              <el-button @click="downloadPaymentFile(policy,payment)" class="button">Скачать</el-button>
            </el-col>
            <el-col :span="8">
              <span>
                {{ formatDateTime(payment.createdAt) }}<br/>
                <span style="color:silver">{{ getReadableFileSizeString(payment.size) }}</span>
                </span>
            </el-col>
            <el-col v-if="payment.decision===false" :span="8">
              Отклонен: {{ payment.decisionComment }}
            </el-col>
            <el-col v-if="payment.decision===null" :span="8">
              <el-input v-model="payment.decisionComment" placeholder="Комментарий к отклонению"></el-input>
            </el-col>
            <el-col v-if="payment.decision===null" :span="5">
              <el-button type="danger" @click="declinePaymentFile(policy,payment)" class="button">Отклонить</el-button>
            </el-col>
            <br/>
            <br/>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axiosApiInstance from "../axios";
import store from "../store";
import router from "../router";
import moment from "moment";

export default {
  name: 'ProcessView',
  mounted() {
    this.loadPolicy()
    this.checkInterval = setInterval(() => {
      if (this.reqCheck) {
        this.reqCheckPercentage -= 10
        if (this.reqCheckPercentage === 0) {
          this.loadPolicy()
          this.reqCheckPercentage = 100
        }
      }
    }, 500);
  },
  unmounted() {
    if (this.checkInterval) {
      this.reqCheckPercentage = 100
      clearInterval(this.checkInterval)
    }
  },
  data: function () {
    return {
      reqCheck: false,
      reqCheckPercentage: 100,
      loading: true,
      policy: {},
      checkInterval: null,
      statusMap: {
        "new": "Ожидает",
        "creating": "Создается",
        "created": "Создан",
        "create_failed": "Ошибка создания",
        "activating": "Активируется",
        "activated": "Активен",
        "activation_failed": "Ошибка активации"
      },
      insurerMap: {
        "alfa": "Альфа",
        "renaissance": "Ренессанс",
        "elt_reso": "ЕЛТ:Ресо",
        "elt_renins": "ЕЛТ:Ренессанс",
        "elt_vsk": "ЕЛТ:ВСК",
        "elt_rgs": "ЕЛТ:Росгосстрах",
      },
    }
  },
  calculated: {
    expire: () => {
      return moment(this.policy.expired).utc(true).toDate()
    }
  },
  methods: {
    getPolicyNumbers() {
      if (this.policy.numbers === undefined) {
        return ""
      }
      let res = []
      for (const resKey in this.policy.numbers) {
        res.push(this.policy.numbers[resKey].series + this.policy.numbers[resKey].number)
      }
      return res.join(",")
    },
    loadPolicy() {
      axiosApiInstance.get(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/policies/" + this.$route.params.id)
          .then((response) => {
            this.reqCheck = response.data.status === 'processing'
                || response.data.status === "activating"
                || response.data.status === "creating"
                || response.data.status === "new";

            this.policy = response.data
            this.policy.isActivable = this.policyActivableValue(this.policy);
            this.policy.isActive = this.policy.status === "activated"
            this.loading = false
          }).catch(error => {
        console.log("view error ", error)
      });
    },
    policyCreated() {
      return this.policy.status === "created" || this.policy.status === "activation_failed"
    },
    policyActivable() {
      return this.policyActivableValue(this.policy)
    },
    policyActivableValue(policy) {
      if (!this.policyActivableStatus(policy)) {
        return false
      }

      console.log("policy expired ", policy.expired, Date.now())
      return policy.expired > Date.now()
    },
    policyActivableStatus(policy) {
      return policy.status === "created" || policy.status === "activation_failed";
    },
    createPolicy() {
      if (this.reqCheck) {
        return
      }
      this.reqCheck = true
      store.commit('createPolicy', {
        offerId: this.policy.offerId,
        onCreate: (data) => {
          this.reqCheck = false
          window.setTimeout(() => {
            router.push({name: 'PolicyView', params: {id: data.id}})
          }, 1000)
        },
        onError: () => {
          this.reqCheck = false
          alert("Ошибка создания полиса.")
          this.loadPolicy()
        }
      })
    },
    activatePolicyByOffer() {
      if (this.reqCheck) {
        return
      }
      if (!confirm("Активировать полис?")) {
        return
      }

      if (!this.policyActivableStatus(this.policy)) {
        alert("Полис в статусе " + this.humanizeStatus(this.policy.status) + " нельзя активировать.")
        return;
      }
      if (!this.policyActivableValue(this.policy)) {
        alert("Полис уже нельзя активировать. Создайте новый полис и передайте данные платежки и нового полиса страховой.")
        return;
      }

      this.reqCheck = true
      this.$store.commit('activatePolicy', {
        policyId: this.policy.id,
        onActivate: () => {
          this.reqCheck = false
          this.loadPolicy()
        },
        onError: () => {
          this.reqCheck = false
          alert("Ошибка активации полиса. Возможно он уже активирован")
          this.loadPolicy()
        }
      })
    },
    formatDate(time) {
      if (time === undefined) {
        return ""
      }
      return moment(time).utc(true).format("YYYY-MM-DD")
    },
    formatDateTime(time) {
      if (time === undefined) {
        return ""
      }
      return moment(time).utc(true).format("YYYY-MM-DD HH:mm:ss")
    },
    recreatable(policy) {
      if (policy.errorCode === 10021) {
        return true;
      }
      if (policy.status !== 'create_failed') {
        return false;
      }
      return policy.expired < Date.now()
    },
    humanizeStatus(status) {
      return this.statusMap[status]
    },
    humanizeInsurer(status) {
      return this.insurerMap[status]
    },
    downloadFile(policy, type) {
      axiosApiInstance.get(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/policies/" + policy.id + "/files/" + type)
          .then((response) => {
            console.log("file url ", response.data.url)
            window.location.href = response.data.url
          }).catch((err) => {
        alert(err)
      })
    },
    downloadPaymentFile(policy, payment) {
      axiosApiInstance.get(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/policies/" + policy.id + "/uploads/" + payment.id)
          .then((response) => {
            console.log("file url ", response.data.url)
            window.location.href = response.data.url
          })
    },
    declinePaymentFile(policy, payment) {
      axiosApiInstance.post(process.env.VUE_APP_OSAGO_SERVICE_URL + "/api/v1/policies/" + policy.id + "/uploads/" + payment.id + "/decline", {
        "decision": false,
        "decisionComment": payment.decisionComment
      })
          .then(() => {
            this.loadPolicy()
          })
    },
    getReadableFileSizeString(fileSizeInBytes) {
      let i = -1;
      const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
      } while (fileSizeInBytes > 1024);

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }
  },
}
</script>

<style scoped>

.data {
  text-align: left;
}

</style>
